import { reactive } from 'vue'
import { required, helpers, minLength, maxLength, minValue, maxValue } from '@vuelidate/validators'
import GeneralRules from './GeneralRules';

class InfoAccionista_JuridicoValidacion{

     public ObtenerReglasValidacion() {

         const objetoInfoAccionista_Juridico = reactive({
             Aj: [{
                NombreAJ: '',
                TipoIdAJ: '',
                NumeroIdentificacionAJ: '',
                ParticipacionCapitalAJ: '',
                PaisAJ: ''
             }]
         });

         const requiredMessage = 'Campo obligatorio';
         
         const patternRule = (pattern: RegExp) => ({
            $validator: (value: string) => pattern.test(value),
            trigger: ['blur', 'change'],
            });

        const validarNumeroPorTipoId = helpers.withMessage
            ('No se admiten espacios ni caracteres especiales', 
                (value: string, {TipoIdAJ}:{TipoIdAJ:string}) => {
                    if(!value || !TipoIdAJ) return false;
                        return GeneralRules.ValidateRegex(value,TipoIdAJ);      
            });
        
        const validarTamanoNumero = helpers.withMessage
            ('El rango de caracteres no es admitido', 
                (value: string, {TipoIdAJ}:{TipoIdAJ:string}) => {
                    if(!value || !TipoIdAJ) return false;
                        return GeneralRules.ValidateSize(value,TipoIdAJ);      
            });
   
        const reglaCaracteresEspeciales = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ]+( [a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$/;
        const reglasPorcentaje = /^(?!0$)(100|[1-9]?\d)$/;

         const reglasValidacion = {
            Aj: {
                 $each: helpers.forEach({
                    NombreAJ: {
                         required: helpers.withMessage(requiredMessage, required),
                         minLength: helpers.withMessage('El rango de caracteres admitidos en este campo es de 2 a 100',minLength(2)),  
                         maxLength: helpers.withMessage('El rango de caracteres admitidos en este campo es de 2 a 100',maxLength(100)),         
                         patternRuleCarecteres: helpers.withMessage('No se admiten espacios al inicio o al final ni caracteres especiales', patternRule(reglaCaracteresEspeciales))
                     },
                     TipoIdAJ: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     NumeroIdentificacionAJ: {
                         required: helpers.withMessage(requiredMessage, required),
                         validarNumeroPorTipoId,
                         validarTamanoNumero
                     },
                     ParticipacionCapitalAJ: {
                         required: helpers.withMessage(requiredMessage, required),
                         minLength: helpers.withMessage('El rango permitido es de 1 a 100',patternRule(reglasPorcentaje)),  
                         patternRuleCarecteres: helpers.withMessage('No se admiten espacios ni caracteres especiales', patternRule(/^[0-9]*$/))
                     },
                     PaisAJ: {
                        required: helpers.withMessage(requiredMessage, required),
                    },
                 })
             }       
         };

        return [objetoInfoAccionista_Juridico, reglasValidacion] as const;
    }
}

export default InfoAccionista_JuridicoValidacion;