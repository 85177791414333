const GeneralRules = {
    OnlyNumbers(e: any) {
        e = (e) ? e : window.event;
        var charCode = (e.which) ? e.which : e.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            e.preventDefault();
        } else {
            return true;
        }
    },
    ValidateRegex(value:string, tipodoc: string):boolean{
        let ExpresionReg;
        switch (tipodoc) {
        //NIT
            case "NIT":
                ExpresionReg = /^[0-9]+$/     
                break;
        //Cédula de ciudadanía
            case "CC":
                ExpresionReg = /^[1-9][0-9]*$/     
                break;
        //Cédula de extranjería
            case "CE":
                ExpresionReg = /^[0-9]+$/    
                break;
        //Tarjeta de identidad
            case "TI":
                ExpresionReg = /^[1-9][0-9]*$/    
                break;
        //Pasaporte
            case "PA":
                ExpresionReg = /^[a-zA-Z0-9]+$/     
                break;
        //Permiso especial
            case "PEP":
                ExpresionReg = /^[a-zA-Z0-9]+$/     
                break;
            default:
                break;
        }
        let valida:boolean = ExpresionReg?.test(value) ? true:false
        return valida
        
    },
    ValidateSize(value:string, tipodoc: string):boolean{
        let ExpresionRegS;
        switch (tipodoc) {
            case "NIT":
                ExpresionRegS = /^.{9,15}$/;
                break;
            case "CC":
                ExpresionRegS = /^.{6,10}$/;
                break;
            case "CE":
                ExpresionRegS = /^.{6,9}$/;
                break;
            case "TI":
                ExpresionRegS = /^.{6,10}$/;
                break;
            case "PA":
                ExpresionRegS = /^.{6,12}$/;
                break;
            case "PEP":
                ExpresionRegS = /^.{10}$/;
                break;
            default:
                break;
        }
        let valida:boolean = ExpresionRegS?.test(value) ? true:false
        return valida
    }
}

export default GeneralRules
