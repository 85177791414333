import { reactive } from 'vue'
import { required, helpers, minLength, maxLength, requiredIf } from '@vuelidate/validators'
import GeneralRules from './GeneralRules';

class InfoBeneficiarioAccionista_JuridicoValidacion{

     public ObtenerReglasValidacion() {

         const objetoInfoBeneficiarioAccionista_Juridico = reactive({
             Beneficiario:[{
                NombreAJ_Beneficiario: '',
                NombreBeneficiario: '',
                TipoIdBeneficiario: '',
                NumeroIdentificacionBeneficiario: '',
                EsPEPBeneficiario: '',
                VinculosFamPEP_Beneficiario: '',
             }]
         });

         const requiredMessage = 'Campo obligatorio';
         
         const patternRule = (pattern: RegExp) => ({
            $validator: (value: string) => pattern.test(value),
            trigger: ['blur', 'change'],
            });

        const validarNumeroPorTipoId = helpers.withMessage
            ('No se admiten espacios ni caracteres especiales', 
                (value: string, {TipoIdBeneficiario}:{TipoIdBeneficiario:string}) => {
                    if(!value || !TipoIdBeneficiario) return false;
                        return GeneralRules.ValidateRegex(value,TipoIdBeneficiario);      
            });

        const validarTamanoNumero = helpers.withMessage
            ('El rango de caracteres no es admitido', 
                (value: string, {TipoIdBeneficiario}:{TipoIdBeneficiario:string}) => {
                    if(!value || !TipoIdBeneficiario) return false;
                        return GeneralRules.ValidateSize(value,TipoIdBeneficiario);      
            });   

        const reglaCaracteresEspeciales = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ]+( [a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$/;

         const reglasValidacion = {
            Beneficiario: {
                 $each: helpers.forEach({
                    NombreAJ_Beneficiario: {
                         required: helpers.withMessage(requiredMessage, required),
                         minLength: helpers.withMessage('El rango de caracteres admitidos en este campo es de 2 a 100',minLength(2)),  
                         maxLength: helpers.withMessage('El rango de caracteres admitidos en este campo es de 2 a 100',maxLength(100)),         
                         patternRuleCarecteres: helpers.withMessage('No se admiten espacios al inicio o al final ni caracteres especiales', patternRule(reglaCaracteresEspeciales))
                     },
                     NombreBeneficiario: {
                         required: helpers.withMessage(requiredMessage, required),
                         minLength: helpers.withMessage('El rango de caracteres admitidos en este campo es de 2 a 100',minLength(2)),  
                         maxLength: helpers.withMessage('El rango de caracteres admitidos en este campo es de 2 a 100',maxLength(100)),         
                         patternRuleCarecteres: helpers.withMessage('No se admiten espacios al inicio o al final ni caracteres especiales', patternRule(reglaCaracteresEspeciales))
                     },
                     TipoIdBeneficiario: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     NumeroIdentificacionBeneficiario: {
                         required: helpers.withMessage(requiredMessage, required),
                         validarNumeroPorTipoId,
                         validarTamanoNumero
                     },
                     EsPEPBeneficiario: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     VinculosFamPEP_Beneficiario: {
                        required: helpers.withMessage(requiredMessage, required),
                    }
                 })
             }       
         };

        return [objetoInfoBeneficiarioAccionista_Juridico, reglasValidacion] as const;
    }
}

export default InfoBeneficiarioAccionista_JuridicoValidacion;