import { createStore } from 'vuex'
import axios from 'axios'
import ddModule from './DueDiligence';

const apiEmpleador=process.env.VUE_APP_Gateway_API+"Empleador/"
const apiDebidaDiligencia=process.env.VUE_APP_Gateway_API+"DebidaDiligencia/"
const apiFront=process.env.VUE_APP_Gateway_API+"FrontEnd/"


export default createStore({
  state: {
    StateLoading:false,
    tokenDD: null
  },
  mutations: {
    setStateLoading(state,Data){
      state.StateLoading=Data;
    },
    cargarToken(state, objetoTokenDD){
      state.tokenDD = objetoTokenDD
    }
  },
  actions: {
    ObtenerEstadoEmpleadorDD({commit, dispatch},data){
      let config = {
        headers: {
          TokenDD: data.TokenDD
        }
      }
      return new Promise ((resolve,reject)=>{
        axios.get(apiEmpleador+"ObtenerEstadoEmpleador?TipoDocumento="+data.TipoDocumento+
        "&NumeroDocumento="+data.NumeroDocumento, config)
          .then((response)=>{
              resolve(response);
          })
          .catch(err=>{
            reject(err);
          });
      }

      )
    },
    GetImages({ commit, dispatch }, nombre) {
      return new Promise((resolve, reject) => {
          axios.get(apiDebidaDiligencia + "DescargarImagenBlobStorage?nombre=" + nombre)
              .then((res) => {
                  resolve(res);
              })
              .catch((err) => {
                  reject(err)
              })
      })
  },
  DescifrarDatosEmpleador({commit, dispatch},data){
    let config = {
      headers: {
        EmpleadorCifrado: data.EmpleadorCifrado
      }
    }
    return new Promise ((resolve,reject)=>{
      axios.get(apiEmpleador+"DescifrarDatosEmpleador", config)
        .then((response)=>{
            commit('cargarToken', response.data.tokenDD);
            resolve(response);
        })
        .catch((err)=>{
          reject(err);
        });
    }

    )
  },
  GetKeyVaultSecrets({ commit, dispatch }, data) {
    let config = {    
      headers: {
        TokenDD: data.TokenDD,
      }    
    }

    return new Promise((resolve, reject) => {
      axios.post(apiFront + 'GetKeyVaultSecrets', data.nombreKeyVault, config)
        .then((response) => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    })
  },
  GetFeatureFlag({ commit, dispatch }, data) {
    let config = {
      headers: {
        TokenDD: data.TokenDD,
      }
    }
    return new Promise((resolve, reject) => {
      axios.get(apiFront + 'GetFeatureFlag?featureFlagName='+ data.featureFlagName, config)
        .then((response) => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    })
  },
  
  ObtenerDepartamentosYMunicipiosDian({ commit, dispatch }, entradaConsulta) {
    let config = {
      headers: {
        TokenDD: entradaConsulta.TokenDD,
      }
    }
    return new Promise((resolve, reject) => {
      axios.get(`${apiDebidaDiligencia}ObtenerDepartamentosYMunicipiosDian`, config)
        .then((response) => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    })
  },
  },
  modules: {
    ddModule
  }
})
