import { computed, reactive } from 'vue'
import { required, helpers, requiredIf } from '@vuelidate/validators'

class DivipolaValidacion {

  public ObtenerReglasValidacion() {

    const divipolas = reactive({ Pais: "", Departamento: "", Municipio: "" });

    const reglasDivipolas = computed(() => {
      return {
        Pais: {
          required: helpers.withMessage(
            `Selecciona un país`,
            required
          ),
        },
        Departamento: {
            required: helpers.withMessage('Seleccione departamento', requiredIf(divipolas.Pais === "COLOMBIA")),
        },
        Municipio: {
            required: helpers.withMessage('Seleccione ciudad', requiredIf(divipolas.Pais === "COLOMBIA"))
        },
      };
    });

    return [divipolas, reglasDivipolas] as const;
  }
}

export default DivipolaValidacion;