<template>
  <el-form>
    <el-row :gutter="24">
      <el-col :span="24">
        <h1 class="section-text">Información accionistas jurídicos</h1>
      </el-col>
    </el-row>

    <el-row :gutter="24">
      <el-col :span="24">
        <p class="pep-text">
          Si algún accionista es Persona Jurídica o empresa, se debe identificar los beneficiarios finales o personas naturales con mayor participación (El beneficiario final corresponde a la persona natural que ejerce control sobre la persona Jurídica o estructura sin personería), a continuación, indique los beneficiarios finales.
        </p>
      </el-col>
    </el-row>

    <el-row :gutter="24">
      <el-col :span="24">
        <p class="pep-text">
          *Se debe relacionar la información de todos los accionistas con una participación mayor al 5%.
        </p>
      </el-col>
    </el-row>

    <div class="juridico">
    <el-row :gutter="24">
      <el-col :span="22">
        <el-table height="300" :data="objetoInfoAccionista_Juridico.Aj" :span="24">

          <el-table-column width="40%" >
              <template v-slot="scope">
                 <span><strong>{{scope.$index + 1}}</strong></span>
                 <div style="height:62px;"></div>
              </template>
          </el-table-column>

          <el-table-column class="section-text-color" label="Nombre del accionista*" width="260px;">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.NombreAJ" :class="[validacionInfoAccionista_Juridico.Aj.$each.$response.$errors[scope.$index].NombreAJ.length ? 'border-invalid' : 'border-valid']" @change="ValidarCamposFila(scope.$index)"/>
              <div style="height:62px;" v-show="!validacionInfoAccionista_Juridico.Aj.$each.$response.$errors[scope.$index].NombreAJ.length"></div>
              <el-form-item v-if="validacionInfoAccionista_Juridico.Aj.$each.$response.$errors[scope.$index].NombreAJ[0]" :key="validacionInfoAccionista_Juridico.Aj.$each.$response.$errors[scope.$index].NombreAJ[0].$message" class="section-error-message">
                {{ validacionInfoAccionista_Juridico.Aj.$each.$response.$errors[scope.$index].NombreAJ[0].$message }}
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column label="Tipo ID*" width="140px">
            <template v-slot="scope">
              <el-select v-model="scope.row.TipoIdAJ" placeholder="Seleccionar" :class="[validacionInfoAccionista_Juridico.Aj.$each.$response.$errors[scope.$index].TipoIdAJ.length ? 'border-invalid' : 'border-valid']" @change="ValidarCamposFila(scope.$index)">
                <el-option v-for="item in parametricas.tipoDeIdentificacion" :key="item.codigo" :label="item.valor" :value="item.valor" />
              </el-select>
              <div style="height:62px;" v-show="!validacionInfoAccionista_Juridico.Aj.$each.$response.$errors[scope.$index].TipoIdAJ.length"></div>
              <el-form-item v-for="error in validacionInfoAccionista_Juridico.Aj.$each.$response.$errors[scope.$index].TipoIdAJ" :key="error" class="section-error-message">
                {{ error.$message }}
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column label="N° ID*" width="180px">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.NumeroIdentificacionAJ" :class="[validacionInfoAccionista_Juridico.Aj.$each.$response.$errors[scope.$index].NumeroIdentificacionAJ.length ? 'border-invalid' : 'border-valid']" @change="ValidarCamposFila(scope.$index)"/>
              <div style="height:62px;" v-show="!validacionInfoAccionista_Juridico.Aj.$each.$response.$errors[scope.$index].NumeroIdentificacionAJ.length"></div>
              <el-form-item v-if="validacionInfoAccionista_Juridico.Aj.$each.$response.$errors[scope.$index].NumeroIdentificacionAJ[0]" :key="validacionInfoAccionista_Juridico.Aj.$each.$response.$errors[scope.$index].NumeroIdentificacionAJ[0].$message" class="section-error-message">
                {{ validacionInfoAccionista_Juridico.Aj.$each.$response.$errors[scope.$index].NumeroIdentificacionAJ[0].$message }}
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column label="% Participación Capital o Voto*" width="170px;">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.ParticipacionCapitalAJ" :class="[validacionInfoAccionista_Juridico.Aj.$each.$response.$errors[scope.$index].ParticipacionCapitalAJ.length ? 'border-invalid' : 'border-valid']" @change="CharPercentage($event,scope.$index);ValidarCamposFila(scope.$index)" @keypress="func.OnlyNumbers"/>
              <div style="height:62px;" v-show="!validacionInfoAccionista_Juridico.Aj.$each.$response.$errors[scope.$index].ParticipacionCapitalAJ.length"></div>
              <el-form-item v-if="validacionInfoAccionista_Juridico.Aj.$each.$response.$errors[scope.$index].ParticipacionCapitalAJ[0]" :key="validacionInfoAccionista_Juridico.Aj.$each.$response.$errors[scope.$index].ParticipacionCapitalAJ[0].$message" class="section-error-message">
                {{ validacionInfoAccionista_Juridico.Aj.$each.$response.$errors[scope.$index].ParticipacionCapitalAJ[0].$message }}
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column label="País de constitución*" width="180px;">
            <template v-slot="scope">
              <el-select v-model="scope.row.PaisAJ" placeholder="Seleccionar" filterable :class="[validacionInfoAccionista_Juridico.Aj.$each.$response.$errors[scope.$index].PaisAJ.length ? 'border-invalid' : 'border-valid']" @change="ValidarCamposFila(scope.$index)">
                <el-option v-for="item in parametricas.pais" :key="item.paisid" :label="item.nombre" :value="item.nombre" />
              </el-select>
              <div style="height:62px;" v-show="!validacionInfoAccionista_Juridico.Aj.$each.$response.$errors[scope.$index].PaisAJ.length"></div>
              <el-form-item v-for="error in validacionInfoAccionista_Juridico.Aj.$each.$response.$errors[scope.$index].PaisAJ" :key="error" class="section-error-message">
                {{ error.$message }}
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column width="50">
            <template v-slot="scope">
              <el-button v-if="scope.$index > 0"  circle class="dinamic-form-button" size="small" type="success" @click="EliminarFilaAJ(scope.$index)">
                <font-awesome-icon
                :icon="['fas', 'minus']"
                />
              </el-button>
                <div style="height:62px;"></div>
            </template>
          </el-table-column>

        </el-table>
      </el-col>

      <el-col :span="2">
        <el-button circle class="dinamic-form-button" size="small" type="success" @click="AgregarFilaAJ">
          <font-awesome-icon
                :icon="['fas', 'plus']"
                />
        </el-button>
      </el-col>
    </el-row>
    </div>
    </el-form>
</template>
<script>

import useVuelidate from '@vuelidate/core';
import { defineComponent, onUpdated, onMounted } from 'vue';
import InfoAccionista_JuridicoValidacion from '../../validation/InfoAccionista_Juridico';
import '../../assets/sass/components/DueDiligence/_legal.scss';
import func from '@/validation/GeneralRules';

export default defineComponent({
    props: {
        scopeInfoAccionista_Juridico: String,
        parametricas: {},
        infoFormularioAccJuridico: {}
    },
    setup(props) {
        
        let [objetoInfoAccionista_Juridico, reglasValidacion] = new InfoAccionista_JuridicoValidacion().ObtenerReglasValidacion();

        const AgregarFilaAJ = () => {

            objetoInfoAccionista_Juridico.Aj.push({
                NombreAJ: '',
                TipoIdAJ: '',
                NumeroIdentificacionAJ: '',
                ParticipacionCapitalAJ: '',
                PaisAJ: ''
            }); 
        }

        const EliminarFilaAJ = (index) => {
            objetoInfoAccionista_Juridico.Aj.splice(index, 1);
        }


        const reiniciarObjetoInfoAccionista_Juridico = () => {
          objetoInfoAccionista_Juridico.Aj = [];
          AgregarFilaAJ();
        }

        const CharPercentage = (e,index) => {
          if(e != '')
          {
              if(!e.includes('%'))
            {
                objetoInfoAccionista_Juridico.Aj[index].ParticipacionCapitalAJ = objetoInfoAccionista_Juridico.Aj[index].ParticipacionCapitalAJ;
            }     
          }         
        }

        const validacionInfoAccionista_Juridico = useVuelidate(reglasValidacion, objetoInfoAccionista_Juridico, { $scope: props.scopeInfoAccionista_Juridico })

        const tiposIdentificacion = [{
            value: "2",
            label: "CC",
          },
          {
            value: "3",
            label: "CE",
          },
          {
            value: "5",
            label: "PAS",
          },
          {
            value: "6",
            label: "PEP",
          }
        ]

        onUpdated(() => {
            window.scrollTo(0, 0);
        })

        onMounted(() => {
          let i;

        if(props.infoFormularioAccJuridico != "No se encuentra diligenciamiento del formulario para el empleador consultado" && props.infoFormularioAccJuridico.aj != null)
        {      
          if((props.infoFormularioAccJuridico.aj.length > 0) && (Object.values(props.infoFormularioAccJuridico.aj[0]).some(x => x !== null && x !== '' && x !== undefined)))
          {
            objetoInfoAccionista_Juridico.Aj.splice(0, 1);
          }

             for(i = 0; i < props.infoFormularioAccJuridico.aj.length; i++)
            {
                if(Object.values(props.infoFormularioAccJuridico.aj[i]).some(x => x !== null && x !== '' && x !== undefined))
                {
                    objetoInfoAccionista_Juridico.Aj.push({
                        NombreAJ: props.infoFormularioAccJuridico.aj[i].nombreAJ,
                        TipoIdAJ: props.infoFormularioAccJuridico.aj[i].tipoIdAJ,
                        NumeroIdentificacionAJ: props.infoFormularioAccJuridico.aj[i].numeroIdentificacionAJ,
                        ParticipacionCapitalAJ: props.infoFormularioAccJuridico.aj[i].participacionCapitalAJ,
                        PaisAJ: props.infoFormularioAccJuridico.aj[i].paisAJ
                    }); 
                }             
            }
        }
      })


        return { 
          AgregarFilaAJ, 
          EliminarFilaAJ,
          validacionInfoAccionista_Juridico, objetoInfoAccionista_Juridico, reiniciarObjetoInfoAccionista_Juridico,func,CharPercentage,
          tiposIdentificacion
        }
    },
    methods:{
        ValidarCamposFila(index)
        {
          let FilaLlena = !Object.values(this.objetoInfoAccionista_Juridico.Aj[index]).some(x => x === null || x === '' || x === undefined)
          if(FilaLlena)
          {
            this.$emit('GenerarGuardadoParcial');
          }
        }
    }
})

</script>

