<template>
  <div class="wrapper-stepper">
    <h2 class="titleDD">Proceso de conocimiento del cliente</h2>
    <div class="stepper">
      <div
        class="stepper-item"
        :class="{
          current: store.state.ddModule.step == item.value,
          success: store.state.ddModule.step > item.value,
        }"
        v-for="item in PasosNatural"
        :key="item.value"
        v-show="validaTipoPersona === 'Natural' || validaTipoPersona === 'Extranjera'"
      >
        <div class="stepper-item-counter">
        </div>
        <span class="stepper-item-title" v-show="store.state.ddModule.step == item.value"> {{ item.label }} </span>
      </div>

      <div
        class="stepper-item"
        :class="{
          current: store.state.ddModule.step == item.value,
          success: store.state.ddModule.step > item.value,
        }"
        v-for="item in PasosJuridico"
        :key="item.value"
        v-show="validaTipoPersona === 'Jurídica'"
      >
        <div class="stepper-item-counter">
        </div>
        <span class="stepper-item-title" v-show="store.state.ddModule.step == item.value"> {{ item.label }} </span>
      </div>

    </div>
    <div class="stepper-content">
      <div
        class="stepper-pane"
        v-show="store.state.ddModule.step == SECCION.CONTACTO"
      >
        <info-contacto v-if="infoFormularioContacto"
          :parametricas="parametricas"
          :infoFormularioContacto="infoFormularioContacto"
          :scopeContacto="scopeContacto"
          :textoDebidaDiligencia="textoDebidaDiligencia"
          @GenerarGuardadoParcial="GenerarGuardadoParcial"
          ref="contactoRef"
          class="width-step"
        ></info-contacto>
      </div>
      <div
        class="stepper-pane"
        v-show="store.state.ddModule.step == SECCION.PEP"
      >
        <pep :scopePep="scopePep" ref="pepRef" v-show="mostrarPEP"
        :parametricas="parametricas"
        :infoFormularioPep="infoFormularioPep"
        @GenerarGuardadoParcial="GenerarGuardadoParcial"
        v-if="infoFormularioPep"
        ></pep>
      </div>
      <div
        class="stepper-pane"
        v-show="store.state.ddModule.step == SECCION.CLIENTE"
      >
        <info-cliente v-if="infoFormularioCliente"
          :parametricas="parametricas"
          :infoFormularioCliente="infoFormularioCliente"
          :scopeCliente="scopeCliente"
          :DeshabilitarCamposJuridicos="validaTipoPersona"
          @GenerarGuardadoParcial="GenerarGuardadoParcial"
          ref="clienteRef"
          v-show="mostrarCliente"
          class="width-step"
        ></info-cliente>
      </div>
      <div
        class="stepper-pane"
        v-show="store.state.ddModule.step == SECCION.FINANCIERA"
      >
        <info-financiera v-if="infoFormularioFinanciera"
          :infoFormularioFinanciera="infoFormularioFinanciera"
          :scopeFinanciera="scopeFinanciera"
          @GenerarGuardadoParcial="GenerarGuardadoParcial"
          ref="FinancieraRef"
          class="width-step"
        ></info-financiera>
      </div>
      <div
        class="stepper-pane"
        v-show="store.state.ddModule.step == SECCION.ADICIONAL"
      >
        <info-adicional
          :parametricas="parametricas"
          :scopeAdicional="scopeAdicional"
          ref="AdicionalRef"
          class="width-step"
        ></info-adicional>
      </div>
      <div v-show="store.state.ddModule.step == SECCION.LEGAL">
        <info-representante-titular
          :parametricas="parametricas"
          :scopeRepresentanteTitular="scopeRepresentanteTitular"
          :infoFormularioRLTitular="infoFormularioRLTitular"
          ref="RepresentanteTitularRef"
          class="width-step"
          @MostrarSeccionPepRT="MostrarSeccionPepRT"
          @GenerarGuardadoParcial="GenerarGuardadoParcial"
          v-if="infoFormularioRLTitular"
        ></info-representante-titular>
        <info-representante-suplente
          :parametricas="parametricas"
          :scopeRepresentanteSuplente="scopeRepresentanteSuplente"
          :infoFormularioRLSuplente="infoFormularioRLSuplente"
          ref="RepresentanteSuplenteRef"
          class="width-step"
          @MostrarSeccionPepRS="MostrarSeccionPepRS"
          @GenerarGuardadoParcial="GenerarGuardadoParcial"
          v-if="infoFormularioRLSuplente"
        ></info-representante-suplente>
        <info-representante-pep
          :parametricas="parametricas"
          :scopeRepresentantePep="scopeRepresentantePep"
          :infoFormularioRLPep="infoFormularioRLPep"
          ref="RepresentantePepRef"
          class="width-step"
          :style="[!mostrarSeccionPep ? { opacity: '0.5' } : {}]"
          :HabilitaCamposPep="mostrarSeccionPep"
          @GenerarGuardadoParcial="GenerarGuardadoParcial"
          v-if="infoFormularioRLPep"
        ></info-representante-pep>
      </div>
      <div v-show="store.state.ddModule.step == SECCION.ACCIONISTA">
        <info-accionista-natural
          :parametricas="parametricas"
          :scopeInfoAccionista_Natural="scopeInfoAccionista_Natural"
          :infoFormularioAccNatural="infoFormularioAccNatural"
          ref="InfoAccionista_NaturalRef"
          class="width-step"
          @MostrarSeccionPepAN="MostrarSeccionPepAN"
          v-show="mostrarAccionistasNaturales"
          @GenerarGuardadoParcial="GenerarGuardadoParcial"
          v-if="infoFormularioAccNatural"
        ></info-accionista-natural>
        <info-pep-accionista-natural
          :parametricas="parametricas"
          :scopeInfoPepAccionista_Natural="scopeInfoPepAccionista_Natural"
          :infoFormularioPepAccNatural="infoFormularioPepAccNatural"
          ref="InfoPepAccionista_NaturalRef"
          class="width-step"
          :style="[!mostrarSeccionPepAN ? { opacity: '0.5' } : {}]"
          :HabilitaCamposPepAN="mostrarSeccionPepAN"
          v-show="mostrarAccionistasNaturales"
          @GenerarGuardadoParcial="GenerarGuardadoParcial"
          v-if="infoFormularioPepAccNatural"
        ></info-pep-accionista-natural>

        <info-accionista-juridico 
            :parametricas="parametricas"
            :scopeInfoAccionista_Juridico="scopeInfoAccionista_Juridico"
            :infoFormularioAccJuridico="infoFormularioAccJuridico"
            ref="InfoAccionista_JuridicoRef"
            class="width-step"
            v-show="mostrarAccionistasJuridicos"
            @GenerarGuardadoParcial="GenerarGuardadoParcial"
            v-if="infoFormularioAccJuridico"
        ></info-accionista-juridico>
        <info-beneficiario-accionista-juridico
            :parametricas="parametricas"
            :scopeInfoBeneficiarioAccionista_Juridico="scopeInfoBeneficiarioAccionista_Juridico"
            :infoFormularioBeneficiario="infoFormularioBeneficiario"
            ref="InfoBeneficiarioAccionista_JuridicoRef"
            class="width-step"
            @MostrarSeccionPepAJ="MostrarSeccionPepAJ"
            v-show="mostrarAccionistasJuridicos"
            @GenerarGuardadoParcial="GenerarGuardadoParcial"
            v-if="infoFormularioBeneficiario"
        ></info-beneficiario-accionista-juridico>
        <info-pep-accionista-juridico
            :parametricas="parametricas"
            :scopeInfoPepAccionista_Juridico="scopeInfoPepAccionista_Juridico"
            :infoFormularioPepAccJuridico="infoFormularioPepAccJuridico"
            ref="InfoPepAccionista_JuridicoRef"
            class="width-step"
            :style="[!mostrarSeccionPepAJ ? { opacity: '0.5' } : {}]"
            :HabilitaCamposPepAJ="mostrarSeccionPepAJ"
            v-show="mostrarAccionistasJuridicos"
            @GenerarGuardadoParcial="GenerarGuardadoParcial"
            v-if="infoFormularioPepAccJuridico"
        ></info-pep-accionista-juridico>
      </div>
    </div>
    
    <div class="controls">
      <el-button
        type="success"
        plain
        @click="RetrocederFormulario"
        :disabled="store.state.ddModule.step == 1"
      >Volver 
      </el-button>
      <el-button 
        type="success" 
        :disabled="finalizar" 
        plain 
        @click="AvanzarFormulario"> 
        {{ store.state.ddModule.step == SECCION.ADICIONAL 
        ? 'Finalizar' 
        : 'Continuar'}} 
      </el-button>
    </div>
  </div>

  <!-- INICIO POP-UP PEP -->

  <el-dialog v-model="pepDialogVisible" id="el-dialog-pep">
    <el-row>
      <el-col>
        <el-form-item>
          <p class="pep-dialog"><strong>¿Sabías qué es el PEP? </strong></p>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col style="color:#706262;">
        <p class="ParrafosPopUp">
        PEP es la Persona Expuesta Políticamente, igualmente se catalogan como
        PEP las personas que tengan una sociedad conyugal, de hecho, o derecho
        con las personas expuestas políticamente nacionales o extranjeras, así
        como a sus familiares hasta el segundo grado de consanguinidad, segundo
        de afinidad y primero civil.
        </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col style="color:#706262">
        <p>
          <strong
            >¿Eres PEP o tienes vínculos familiares con un PEP?</strong
          >
        </p>
      </el-col>
    </el-row>
    <span class="dialog-footer">
      <el-button type="success" plain @click="AvanzarClienteIfNoPep"
        >No</el-button
      >
      <el-button type="success" plain @click="AvanzarPEP">Si</el-button>
    </span>
  </el-dialog>

  <!-- FIN POP-UP PEP -->

  <!-- INICIO POP-UP VALIDAR ACCIONISTAS -->

  <el-dialog v-model="AccionistasDialogVisible" id="el-dialog-Accionistas" class="elDialogAccionistas">
    <el-row class="PopUpCompAccionaria">
      <el-col>
        <el-form-item>
          <p class="PopUpTitle"><strong>Composición Accionaria </strong></p>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="PopUpCompAccionaria">
      <el-col style="color:#706262; text-align: justify; word-break: keep-all;">
        Se deben relacionar todos los accionistas o asociados que tengan directa
        o indirectamente el 5% o más de su capital social, aporte o
        participación.
      </el-col>
    </el-row>
    <el-row class="PopUpCompAccionaria">
      <el-col style="color:#706262">
        <p>
          <strong>¿Cuentas con accionistas o asociados a tu empresa?</strong>
        </p>
      </el-col>
    </el-row>
    <el-row class="PopUpCompAccionaria">
        <el-radio-group v-model="radioAccionistas" style="display:inline" >    
          <div class="EspacioRadio" v-show="contactoRef.objetoContacto.Data.ClaseSociedad == 'Sociedad Anónima'">
            <el-radio :label="1" style="color:#706262">No cuento con accionistas</el-radio> 
          </div>        
          <div class="EspacioRadio">
            <el-radio :label="2" style="color:#706262" selected>Si, cuento con accionistas naturales</el-radio>
          </div>
          <div class="EspacioRadio">
            <el-radio :label="3" style="color:#706262">Si, cuento con accionistas jurídicos</el-radio>
          </div>
          <div class="EspacioRadio">
            <el-radio :label="4" style="color:#706262">Si, cuento con ambos tipos de accionistas</el-radio>
          </div>
        </el-radio-group>
    </el-row>
    <span class="dialog-footer">
      <el-button
        type="success"
        plain
        @click="AvanzarAccionistas(radioAccionistas)"
        >Aceptar</el-button
      >
    </span>
  </el-dialog>
   <Spinner v-if="mostrarSpinner" type="modern"></Spinner>
  <!-- FIN POP-UP PEP -->
</template>


<script>
import { useStore } from "vuex";
import { ElMessage } from 'element-plus'
import router from '@/router';
import{ useRoute } from 'vue-router';
import { computed, ref, defineComponent,onBeforeMount, onMounted } from "vue";
import useVuelidate from "@vuelidate/core";
import Seccion from "../models/DueDiligence/Seccion";
import InfoContacto from "@/components/DueDiligence/InfoContacto.vue";
import Pep from "@/components/DueDiligence/Pep.vue";
import InfoCliente from "@/components/DueDiligence/InfoCliente.vue";
import InfoFinanciera from "../components/DueDiligence/InfoFinanciera.vue";
import InfoAdicional from "../components/DueDiligence/InfoAdicional.vue";
import InfoRepresentanteTitular from "../components/DueDiligence/InfoRepresentanteTitular.vue";
import InfoRepresentanteSuplente from "../components/DueDiligence/InfoRepresentanteSuplente.vue";
import InfoRepresentantePep from "../components/DueDiligence/InfoPEPRepresentante.vue";
import InfoAccionistaNatural from "../components/DueDiligence/InfoAccionista_Natural.vue";
import InfoPepAccionistaNatural from "../components/DueDiligence/InfoPepAccionista_Natural.vue";
import InfoAccionistaJuridico from "../components/DueDiligence/InfoAccionista_Juridico.vue";
import InfoBeneficiarioAccionistaJuridico from "../components/DueDiligence/InfoBeneficiarioAccionista_Juridico.vue";
import InfoPepAccionistaJuridico from "../components/DueDiligence/InfoPepAccionista_Juridico.vue";
import Spinner from "@/components/layout/Spinner.vue";
import EstadoDebidaDiligencia from "../models/DueDiligence/EstadoDebidaDiligencia";
import { useMutations } from "@/store/helper";


export default defineComponent({
  components: {
    InfoContacto,
    InfoCliente,
    Pep,
    InfoFinanciera,
    InfoAdicional,
    InfoRepresentanteTitular,
    InfoRepresentanteSuplente,
    InfoRepresentantePep,
    InfoAccionistaNatural,
    InfoPepAccionistaNatural,
    InfoAccionistaJuridico,
    InfoBeneficiarioAccionistaJuridico,
    InfoPepAccionistaJuridico,
    Spinner
  },
  setup() {
    const store = useStore();
    const{CargarDivipolas} = useMutations(["CargarDivipolas"]);
    const route = useRoute();
    const SECCION = ref(Seccion); 
    const finalizar = ref(false);
    const mensajeErrorDD = "Recuerde llenar todos los campos obligatorios (*)";
    var TiempoVigenciaToken = ref();
    const OmitirPreventDefault = ref(false);
    const PasosNatural = [
          {
            value: "1",
            label: "Información general",
          },
          {
            value: "2",
            label: "Información PEP",
          },
          {
            value: "3",
            label: "Información cliente",
          },
          {
            value: "4",
            label: "Información financiera",
          },
          {
            value: "5",
            label: "Información adicional"
          }
        ];

        const PasosJuridico = [
          {
            value: "1",
            label: "Información general",
          },
          {
            value: "2",
            label: "Información cliente",
          },
          {
            value: "3",
            label: "Información legal",
          },
          {
            value: "4",
            label: "Información accionista",
          },
          {
            value: "5",
            label: "Información financiera"
          },
          {
            value: "6",
            label: "Información adicional"
          },

        ];

    const mostrarSpinner = ref(false);

    //CONTACTO:
    const contactoRef = ref();
    const scopeContacto = "contacto";
    const validacionContacto = useVuelidate(
      {},
      {},
      {
        $scope: scopeContacto,
      }
    );
    const validaTipoPersona = ref("Jurídica");
    const infoFormularioContacto = ref("");

    //PEP:
    const pepRef = ref();
    const scopePep = "pep";
    const mostrarPEP = ref(false);
    const pepDialogVisible = ref(false);
    const validacionPep = useVuelidate(
      {},
      {},
      {
        $scope: scopePep,
      }
    );
    const RegistraPep = ref();
    const infoFormularioPep = ref("");

    //CLIENTE
    const clienteRef = ref();
    const mostrarCliente = ref(false);
    const scopeCliente = "cliente";
    const validacionCliente = useVuelidate(
      {},
      {},
      {
        $scope: scopeCliente,
      }
    );
    const infoFormularioCliente = ref("");

    //INFO FINANCIERA
    const FinancieraRef = ref();
    const scopeFinanciera = "financiera";
    const validacionFinanciera = useVuelidate(
      {},
      {},
      {
        $scope: scopeFinanciera,
      }
    );
    const infoFormularioFinanciera = ref("");


  //INFO ADICIONAL
    const AdicionalRef = ref();
    const scopeAdicional = 'adicional'
    const validacionAdicional = useVuelidate({},{},{
        $scope: scopeAdicional
    })

    //INFO REPRESENTANTE TITULAR (INFO LEGAL)
    const RepresentanteTitularRef = ref();
    const scopeRepresentanteTitular = "Representante Titular";
    const validacionInfoRepresentanteTitular = useVuelidate(
      {},
      {},
      {
        $scope: scopeRepresentanteTitular,
      }
    );
    const EstadoPEPRT = ref(false);
    const infoFormularioRLTitular = ref("");

    //INFO REPRESENTANTE SUPLENTE (INFO LEGAL)
    const RepresentanteSuplenteRef = ref();
    const scopeRepresentanteSuplente = "Representante Suplente";
    const EstadoPEPRS = ref(false);
    const validacionInfoRepresentanteSuplente = useVuelidate(
      {},
      {},
      {
        $scope: scopeRepresentanteSuplente,
      }
    );
    const infoFormularioRLSuplente = ref("");

    //INFO REPRESENTANTE PEP (INFO LEGAL)
    const mostrarSeccionPep = ref(false);
    const RepresentantePepRef = ref();
    const scopeRepresentantePep = "Representante PEP";
    const validacionInfoRepresentantePep = useVuelidate(
      {},
      {},
      {
        $scope: scopeRepresentantePep,
      }
    );
    const infoFormularioRLPep = ref("");

    //POP UP VALIDAR ACCIONISTAS
    const AccionistasDialogVisible = ref(false);
    const radioAccionistas = ref(2);

    //MOSTRAR/OCULTAR ACCIONISTAS
    const mostrarAccionistasNaturales = ref(false);
    const mostrarAccionistasJuridicos = ref(false);

    //INFO ACCIONISTAS NATURALES (INFO ACCIONISTAS)
    const InfoAccionista_NaturalRef = ref();
    const scopeInfoAccionista_Natural = "Accionista Natural";
    const validacionInfoAccionista_Natural = useVuelidate(
      {},
      {},
      {
        $scope: scopeInfoAccionista_Natural,
      }
    );
    const infoFormularioAccNatural = ref("");

    //INFO PEP ACCIONISTAS NATURALES (INFO ACCIONISTAS)
    const mostrarSeccionPepAN = ref(false);
    const InfoPepAccionista_NaturalRef = ref();
    const scopeInfoPepAccionista_Natural = "Pep Accionista Natural";
    const validacionInfoPepAccionista_Natural = useVuelidate(
      {},
      {},
      {
        $scope: scopeInfoPepAccionista_Natural,
      }
    );
    const infoFormularioPepAccNatural = ref("");

    //INFO ACCIONISTAS JURIDICOS (INFO ACCIONISTAS)
    const InfoAccionista_JuridicoRef = ref();
    const scopeInfoAccionista_Juridico = "Accionista Juridico";
    const validacionInfoAccionista_Juridico = useVuelidate(
      {},
      {},
      {
        $scope: scopeInfoAccionista_Juridico,
      }
    );
    const infoFormularioAccJuridico = ref("");

    //INFO BENEFICIARIOS ACCIONISTAS JURIDICOS (INFO ACCIONISTAS)
    const InfoBeneficiarioAccionista_JuridicoRef = ref();
    const scopeInfoBeneficiarioAccionista_Juridico = "Beneficiario Accionista Juridico";
    const validacionInfoBeneficiarioAccionista_Juridico = useVuelidate(
      {},
      {},
      {
        $scope: scopeInfoBeneficiarioAccionista_Juridico,
      }
    );
    const infoFormularioBeneficiario = ref("");

    //INFO PEP ACCIONISTAS JURIDICOS (INFO ACCIONISTAS)
    const mostrarSeccionPepAJ = ref(false);
    const InfoPepAccionista_JuridicoRef = ref();
    const scopeInfoPepAccionista_Juridico = "Pep Accionista Juridico";
    const validacionInfoPepAccionista_Juridico = useVuelidate(
      {},
      {},
      {
        $scope: scopeInfoPepAccionista_Juridico,
      }
    );
    const infoFormularioPepAccJuridico = ref("");

    ///AVANCE Y RETROCESO DEL STEPPER:
    const stepperProgress = computed(() => {
      return (100 / 3) * (store.state.ddModule.step.value - 1) + "%";
    });

    //REF PARA CARGUE DE PARAMÉTRICAS EN STEPS
    const parametricas = ref({});
    
    let featureFlag = ref(false);
    let textoDebidaDiligencia = ref ("");

    function CargarParametrosParaFormulario()
    {
      let ParametrosParaDesencriptarEmpleador = {
        EmpleadorCifrado: route.params.EmpleadorCifrado
      }
      mostrarSpinner.value = true;
      store.dispatch("DescifrarDatosEmpleador",ParametrosParaDesencriptarEmpleador).then(resp=>{
         localStorage.setItem('TipoIdenticacionDD',resp.data.tipoDocumentoDescifrado);
         localStorage.setItem('NumeroIdentificacionDD',resp.data.numeroDocumentoDescifrado);
         localStorage.setItem('RazonSocialDD',resp.data.razonSocialDescifrada);
         localStorage.setItem('IdProd',resp.data.idProductoDescifrado);   
         GetParametricas(resp.data.tokenDD);
         ObtenerDivipolas(resp.data.tokenDD);
      }).catch((error) => {
                 console.log(error);
                 mostrarSpinner.value = false;
                 RedireccionEnError(error);
                 });
    }

    function GetParametricas(tokenDD) {
    store.dispatch("GetParametricasDebidaDiligencia",tokenDD).then(resp=> {
        parametricas.value = store.state.ddModule.parametricas;   
        const TiempoActual = new Date(Date.now());
        TiempoVigenciaToken.value = new Date(TiempoActual.setHours(TiempoActual.getHours() + 2));
        ObtenerGuardadoParcial(tokenDD)
        }).catch((error) => {              
                 console.log(error);                            
                 });
    }

    function ObtenerDivipolas(tokenDD) {
        let consultaDivipolas = {
          TokenDD: tokenDD,
        };
        store
          .dispatch("ObtenerDepartamentosYMunicipiosDian", consultaDivipolas)
          .then((response) => {
            if (response.request.status == 200) { 
              CargarDivipolas({
                departamentos: response.data.Departamentos,
                municipios: response.data.Municipios
              })
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }

    function ObtenerGuardadoParcial(tokenDD)
    {
      let ParametrosParaObtenerGuardadoParcial = {
        TokenDD: tokenDD,
        TipoIdentificacion: localStorage.getItem('TipoIdenticacionDD'),
        NumeroIdentificacion: localStorage.getItem('NumeroIdentificacionDD')
      }

      let RLTitularTienePep;
      let RLSuplenteTienePep; 

      let InfoLegalTienePep;

      let AccNaturalTienePep;
      let AccJuridicoTienePep;

      store.dispatch("ObtenerInfoFormularioDebidaDiligencia",ParametrosParaObtenerGuardadoParcial).then(resp=>{       
                      if(resp.data.mensaje != null)
                      {
                        infoFormularioContacto.value = resp.data.mensaje;
                        infoFormularioCliente.value = resp.data.mensaje;
                        infoFormularioFinanciera.value = resp.data.mensaje;
                        infoFormularioPep.value = resp.data.mensaje;
                        infoFormularioRLTitular.value = resp.data.mensaje;
                        infoFormularioRLSuplente.value = resp.data.mensaje;
                        infoFormularioRLPep.value = resp.data.mensaje;
                        infoFormularioAccNatural.value = resp.data.mensaje;
                        infoFormularioPepAccNatural.value = resp.data.mensaje;
                        infoFormularioAccJuridico.value = resp.data.mensaje;
                        infoFormularioBeneficiario.value = resp.data.mensaje;
                        infoFormularioPepAccJuridico.value = resp.data.mensaje;
                      }
                      else
                      {
                        infoFormularioContacto.value = resp.data.jsonRegistro.contacto;
                        infoFormularioCliente.value = resp.data.jsonRegistro.cliente;
                        infoFormularioFinanciera.value = resp.data.jsonRegistro.financiera;        
                        infoFormularioPep.value = resp.data.jsonRegistro.pep;

                        infoFormularioRLTitular.value = resp.data.jsonRegistro.legal.repLegalTitular;
                        infoFormularioRLSuplente.value = resp.data.jsonRegistro.legal.repLegalSuplente;
                        infoFormularioRLPep.value = resp.data.jsonRegistro.legal.repLegalPep;
                    
                        if((resp.data.jsonRegistro.legal.repLegalPep.rlPep != null))
                        {       
                            RLTitularTienePep = (Object.values(resp.data.jsonRegistro.legal.repLegalTitular.rlTitular).filter(e => e.esPEPRLTitular == 'Si' || e.vinculosFamPEP_RLTitular == 'Si').length > 0);
                            RLSuplenteTienePep = (Object.values(resp.data.jsonRegistro.legal.repLegalSuplente.rlSuplente).filter(e => e.esPEPRLSuplente == 'Si' || e.vinculosFamPEP_RLSuplente == 'Si').length > 0);

                            InfoLegalTienePep = RLTitularTienePep || RLSuplenteTienePep ? true : false;
                            EstadoPEPRT.value = RLTitularTienePep ? true : false;
                            EstadoPEPRS.value = RLSuplenteTienePep ? true : false;

                            if(InfoLegalTienePep)
                            {
                              mostrarSeccionPep.value = true;
                            }          
                        }

                        infoFormularioAccNatural.value = resp.data.jsonRegistro.accionistaNatural.accNatural;
                        infoFormularioPepAccNatural.value = resp.data.jsonRegistro.accionistaNatural.pepAccNatural;

                        if((resp.data.jsonRegistro.accionistaNatural.pepAccNatural.pepAN != null))
                        {         
                            AccNaturalTienePep = (Object.values(resp.data.jsonRegistro.accionistaNatural.accNatural.an).filter(e => e.esPEPAN == 'Si' || e.vinculosFamPEP_AN == 'Si').length > 0);

                            if(AccNaturalTienePep)
                            {
                              mostrarSeccionPepAN.value = true;
                            }          
                        }

                        infoFormularioAccJuridico.value = resp.data.jsonRegistro.accionistaJuridico.accJuridico;
                        infoFormularioBeneficiario.value = resp.data.jsonRegistro.accionistaJuridico.beneficiarioJuridico;
                        infoFormularioPepAccJuridico.value = resp.data.jsonRegistro.accionistaJuridico.pepAccJuridico;

                        if((resp.data.jsonRegistro.accionistaJuridico.pepAccJuridico.pepAJ != null))
                        {         
                            AccJuridicoTienePep = (Object.values(resp.data.jsonRegistro.accionistaJuridico.beneficiarioJuridico.beneficiario).filter(e => e.esPEPBeneficiario == 'Si' || e.vinculosFamPEP_Beneficiario == 'Si').length > 0);

                            if(AccJuridicoTienePep)
                            {
                              mostrarSeccionPepAJ.value = true;
                            }          
                        }
                      }        
                      ObtenerTextoDebidaDiligencia(tokenDD)                       
      }).catch(error=>{
                 console.log(error);              
                 });
    }

    function ObtenerTextoDebidaDiligencia(tokenDD){
      const data = 
      {
        TokenDD: tokenDD,
        nombreKeyVault: ['textoDebidaDiligencia']
      }
      store.dispatch('GetKeyVaultSecrets', data).then(response => {
        let secrets = response.data;
        textoDebidaDiligencia.value = secrets[0].value;       
        mostrarSpinner.value = false;
          })
          .catch((err) => {
            mostrarSpinner.value = false;
            console.error(err);
          });
      }

    const RedireccionEnError = (error) =>
    {
      if(error.request.status == 401)
      {                
          router.push({name:'UnAutorized'});
          setTimeout(() => {
              window.open(process.env.VUE_APP_PortalEmpleador,'_self');
          }, 4000);         
      }
    }

    const RedireccionEnErrorRef = ref(RedireccionEnError)

    onBeforeMount(() => { 
            CargarParametrosParaFormulario();                
      });

    return {
      store, stepperProgress, SECCION, finalizar, mostrarSpinner,mensajeErrorDD, TiempoVigenciaToken, OmitirPreventDefault, PasosNatural, PasosJuridico, RedireccionEnErrorRef, featureFlag, ObtenerTextoDebidaDiligencia, textoDebidaDiligencia,
      scopeContacto, contactoRef, validacionContacto, validaTipoPersona,
      scopePep, pepRef, validacionPep,
      pepDialogVisible, mostrarPEP, RegistraPep,
      scopeCliente, clienteRef, validacionCliente, mostrarCliente,
      scopeFinanciera,FinancieraRef,validacionFinanciera,
      scopeAdicional,AdicionalRef,validacionAdicional,
      scopeRepresentanteTitular,RepresentanteTitularRef,validacionInfoRepresentanteTitular,EstadoPEPRT,
      scopeRepresentanteSuplente,RepresentanteSuplenteRef,EstadoPEPRS, validacionInfoRepresentanteSuplente,
      scopeRepresentantePep,RepresentantePepRef,validacionInfoRepresentantePep,mostrarSeccionPep,
      AccionistasDialogVisible,radioAccionistas,
      mostrarAccionistasNaturales,mostrarAccionistasJuridicos,
      scopeInfoAccionista_Natural,InfoAccionista_NaturalRef,validacionInfoAccionista_Natural,
      scopeInfoPepAccionista_Natural,InfoPepAccionista_NaturalRef,validacionInfoPepAccionista_Natural,mostrarSeccionPepAN,
      scopeInfoAccionista_Juridico, InfoAccionista_JuridicoRef, validacionInfoAccionista_Juridico,
      scopeInfoBeneficiarioAccionista_Juridico, InfoBeneficiarioAccionista_JuridicoRef,validacionInfoBeneficiarioAccionista_Juridico,
      scopeInfoPepAccionista_Juridico, InfoPepAccionista_JuridicoRef, validacionInfoPepAccionista_Juridico, mostrarSeccionPepAJ,onBeforeMount, parametricas,
      infoFormularioContacto, infoFormularioCliente, infoFormularioFinanciera,infoFormularioPep,
      infoFormularioRLTitular, infoFormularioRLSuplente, infoFormularioRLPep,
      infoFormularioAccNatural, infoFormularioPepAccNatural, 
      infoFormularioAccJuridico, infoFormularioBeneficiario, infoFormularioPepAccJuridico,
      EstadoDebidaDiligencia
    };
  },
  methods: {
    ModificarPasosIfNatural() {
      this.SECCION = {
        CONTACTO: 1,
        PEP: 2,
        CLIENTE: 3,
        FINANCIERA: 4,
        ADICIONAL: 5,
        LEGAL:0,
        ACCIONISTA:0
      };
    },

    ModificarPasosIfJuridico() {
      this.SECCION = {
        CONTACTO: 1,
        CLIENTE: 2,
        LEGAL: 3,
        ACCIONISTA: 4,
        FINANCIERA: 5,
        ADICIONAL: 6,
        PEP: 0       
      };
    },

    AvanzarFormulario() {
       const TiempoActual = new Date(Date.now())
       const VigenciaConvertida = new Date(this.TiempoVigenciaToken)
       
       if(VigenciaConvertida > TiempoActual)
       {       
        //Validación de pasos para stepper y variable global según tipo persona
      this.validaTipoPersona = this.contactoRef.objetoContacto.Data.TipoPersona;

        
        //validar estado de debida diligencia
      this.ValidarDebidaDiligencia()
        
        //Steps Persona Natural/Extranjera

        //Step 1 - Persona Natural/Extranjera (CONTACTO)
      if (this.store.state.ddModule.step === this.SECCION.CONTACTO && this.validaTipoPersona !== "Jurídica") {
        this.FlujoInfoContactoPNatural();
        return;
      }

      //Step 2 - Persona Natural/Extranjera (PEP)
      if (this.store.state.ddModule.step === this.SECCION.PEP) {
        this.FlujoInfoPEPPNatural();
        return;
      }

      //Step 3 Persona Natural/Extranjera (CLIENTE)
      if (this.store.state.ddModule.step === this.SECCION.CLIENTE && (this.validaTipoPersona !== "Jurídica")) {
      this.FlujoInfoClientePNatural();
      return;
      }

      //Step Info Financiera
      if (this.store.state.ddModule.step === this.SECCION.FINANCIERA) {
        // this.validacionFinanciera.$validate();
        // if (!this.validacionFinanciera.$error) {
          this.AdicionalRef.objetoArchivos.EstadosFinancieros.InformacionFinanciera =  this.FinancieraRef.objetoFinanciera;
          this.CargarInfoFinancieraEsquemaJson();
          this.RegistrarGuardadoParcial();     
          this.store.dispatch("Avanzar");
        // }
        // else{
        //     ElMessage.error(this.mensajeErrorDD);
        // }
        return;
      }

        //step final (Info Adicional)
      if (this.store.state.ddModule.step === this.SECCION.ADICIONAL) {
          this.validacionAdicional.$validate();
          if (!this.validacionAdicional.$error) {

            this.finalizar = true;
            this.mostrarSpinner = true;

            this.store.dispatch("CargarInformacionCompromisos", {
               BuenasPracticas: this.AdicionalRef.objetoArchivos.BuenasPracticas,
               ConflictoInteres: this.AdicionalRef.objetoArchivos.ConflictoInteres
            });

            this.AdicionalRef.objetoArchivos.Archivos
              .append('EntradaDebidaDiligencia', 
                JSON.stringify(this.store.state.ddModule.debidaDiligencia))

            let params = {
              TokenDD: this.store.state.tokenDD,
              TipoIdentificacion: this.contactoRef.objetoContacto.Data.TipoDocumento,
              NumeroIdentificacion: this.contactoRef.objetoContacto.Data.No_Identificacion,
              RazonSocial: this.contactoRef.objetoContacto.Data.Nombre,
              IdProd: localStorage.getItem("IdProd"),
              Archivos: this.AdicionalRef.objetoArchivos.Archivos
            };
            this.store.dispatch('RegistrarDebidaDiligencia', params)
            .then((data) => {
                this.mostrarSpinner = false;
                if(data.status == 204)
                {
                  this.EliminarGuardadoParcial();
                  router.push({ name: "Dashboard" });
                }             
            })
            .catch((error) => {
              this.mostrarSpinner = false;
              console.log(error);
              this.RedireccionEnErrorRef(error);    
            });
          }
          else{
            ElMessage.error(this.mensajeErrorDD)
        }
          return;
        }
      
        //Steps Persona Jurídica

        //Step 1 Persona Jurídica (INFO CONTACTO)
      if (this.store.state.ddModule.step === this.SECCION.CONTACTO && this.validaTipoPersona === "Jurídica") {
        this.FlujoInfoContactoPJuridica();
        return;
      }

      //Step 2 Persona Jurídica (INFO CLIENTE)
      if (this.store.state.ddModule.step === this.SECCION.CLIENTE && this.validaTipoPersona === "Jurídica") {
        this.FlujoInfoClientePJuridica();
        return;
      }

      //Step 3 Persona Jurídica (INFO LEGAL)
      if (this.store.state.ddModule.step === this.SECCION.LEGAL) {
        this.FlujoInfoLegalPJuridica();
        return;
      }

      //Step 4 Persona Jurídica (ACCIONISTA NATURAL)

      if (this.store.state.ddModule.step === this.SECCION.ACCIONISTA && this.mostrarAccionistasNaturales) {
        this.validacionInfoAccionista_Natural.$validate();
        if (this.mostrarSeccionPepAN) {
          this.FlujoAccNaturalIfPEP();
        } 
        else {
          this.FlujoAccNaturalIfNoPEP();
        }
        return;
      }

      //Step 4 Persona Jurídica (ACCIONISTA JURÍDICO)

      if (this.store.state.ddModule.step === this.SECCION.ACCIONISTA && this.mostrarAccionistasJuridicos) {     
          this.validacionInfoAccionista_Juridico.$validate();
          this.validacionInfoBeneficiarioAccionista_Juridico.$validate();
          if(this.mostrarSeccionPepAJ)
          {
              this.FlujoAccJuridicoIfPEP();
          }
          else
          {
              this.FlujoAccJuridicoIfNoPEP();
          }
          return;
      } 
      }
     else
     {
        router.push({name:'UnAutorized'});
        this.OmitirPreventDefault = true;
        setTimeout(() => {
            window.open(process.env.VUE_APP_PortalEmpleador,'_self');
        }, 4000); 
     }
    },
    AvanzarCliente() {
      this.mostrarPEP = false;
      this.mostrarCliente = true;
      this.pepDialogVisible = false;
      this.store.dispatch("Avanzar");
    },
    AvanzarClienteIfNoPep() {
      this.mostrarPEP = false;
      this.mostrarCliente = true;
      this.pepDialogVisible = false;
      this.store.dispatch("AvanzarDosPasos");
      this.store.dispatch("EliminarInformacionPEP");
      this.pepRef.reiniciarObjetoPEP();
      this.RegistraPep = false;
    },
    AvanzarPEP() {
      this.mostrarPEP = true;
      this.mostrarCliente = false;
      this.pepDialogVisible = false;
      this.store.dispatch("Avanzar");
    },
    RetrocederFormulario() {
      const TiempoActual = new Date(Date.now());
      const VigenciaConvertida = new Date(this.TiempoVigenciaToken);

      if(VigenciaConvertida > TiempoActual)
       {
        //Steps Retroceder Persona Natural

        //Retroceder a CONTACTO cuando se está en PEP
      if (this.store.state.ddModule.step === this.SECCION.PEP) {
        this.store.dispatch("Retroceder");
        return;
      }

        //Retroceder a CONTACTO o a PEP cuando está en CLIENTE
      if (this.store.state.ddModule.step === this.SECCION.CLIENTE && this.validaTipoPersona !== "Jurídica") {
        if (this.RegistraPep) {
          this.store.dispatch("Retroceder");
          this.mostrarPEP = true;
          this.mostrarCliente = false;
          this.pepDialogVisible = false;
        } else {
          this.store.dispatch("RetrocederDosPasos");
        }
        return;
      }

      //Retroceder a CLIENTE cuando se está en FINANCIERA
      if (this.store.state.ddModule.step === this.SECCION.FINANCIERA && this.validaTipoPersona !== "Jurídica") {
          this.store.dispatch("Retroceder");
          return;
      }

      //Retroceder a FINANCIERA o a CLIENTE cuando se está en ADICIONAL
      if (this.store.state.ddModule.step === this.SECCION.ADICIONAL && this.validaTipoPersona !== "Jurídica") {
        if (
          this.RegistraPep ||
          this.clienteRef.objetoCliente.ActivosVirtuales === "Si"
        ) {
          this.store.dispatch("Retroceder");
        } else {
          this.store.dispatch("RetrocederDosPasos");
        }
        return;
      }

      //Steps Retroceder Persona Jurídica
      
        //Retroceder a CONTACTO cuando se está en CLIENTE
      if(this.store.state.ddModule.step === this.SECCION.CLIENTE && this.validaTipoPersona === "Jurídica")
      {
          this.store.dispatch("Retroceder");
          this.mostrarCliente = false;
          return;
      }

      //Retroceder a CLIENTE cuando se está en LEGAL
      if(this.store.state.ddModule.step === this.SECCION.LEGAL)
      {
          this.store.dispatch("Retroceder");
          this.mostrarCliente = true;
          return;
      }

      //Retroceder a LEGAL cuando se está en ACCIONISTA NATURAL
      if(this.store.state.ddModule.step === this.SECCION.ACCIONISTA && this.mostrarAccionistasNaturales)
      {
          this.store.dispatch("Retroceder");
          return;
      }

      //Retroceder a ACCIONISTA NATURAL o a LEGAL cuando se está en ACCIONISTA JURÍDICO
      if(this.store.state.ddModule.step === this.SECCION.ACCIONISTA && this.mostrarAccionistasJuridicos)
      {
          if(this.radioAccionistas === 3)
          {
              this.store.dispatch("Retroceder");
          }
          else
          {
              this.mostrarAccionistasNaturales = true;
              this.mostrarAccionistasJuridicos = false;
          }
          return;
      }

       //Retroceder a ACCIONISTA NATURAL o ACCIONISTA JURÍDICO o LEGAL cuando se está en FINANCIERA
      if(this.store.state.ddModule.step === this.SECCION.FINANCIERA && this.validaTipoPersona === "Jurídica"){
          if(this.radioAccionistas === 1)
          {
              this.store.dispatch("RetrocederDosPasos");
          }else{
              if(this.radioAccionistas === 2)
              {
                  this.store.dispatch("Retroceder");
                  this.mostrarAccionistasNaturales = true;
                  this.mostrarAccionistasJuridicos = false;
              }else{        
                    this.store.dispatch("Retroceder");
                    this.mostrarAccionistasNaturales = false;
                    this.mostrarAccionistasJuridicos = true;            
              }
          }
          return;
      }
          
        //Retroceder a FINANCIERA o ACCIONISTA JURIDICO o ACCIONISTA NATURAL o LEGAL cuando se está en ADICIONAL
      if(this.store.state.ddModule.step === this.SECCION.ADICIONAL && this.validaTipoPersona === "Jurídica")
      {
          if(this.mostrarSeccionPep || this.mostrarSeccionPepAN || this.mostrarSeccionPepAJ || this.clienteRef.objetoCliente.ActivosVirtuales === "Si")
          {
              this.store.dispatch("Retroceder");
          }
          else{
              if(this.radioAccionistas === 1)
              {
                  this.store.dispatch("RetrocederTresPasos")
              }
              else
              if(this.radioAccionistas === 2)
              {
                  this.store.dispatch("RetrocederDosPasos");
                  this.mostrarAccionistasNaturales = true;
                  this.mostrarAccionistasJuridicos = false
              }
              else
              {
                  this.store.dispatch("RetrocederDosPasos");
                  this.mostrarAccionistasNaturales = false;
                  this.mostrarAccionistasJuridicos = true;
              }
              
          }
          return;
      }   
     }
     else
     {
        router.push({name:'UnAutorized'});
        this.OmitirPreventDefault = true;
        setTimeout(() => {
            window.open(process.env.VUE_APP_PortalEmpleador,'_self');
        }, 4000); 
     }   

    },
    FlujoInfoContactoPNatural(){
        this.validacionContacto.$validate();
        if (!this.validacionContacto.$error) {
          this.GenerarGuardadoParcialPNatural();
          this.ModificarPasosIfNatural();
          this.pepDialogVisible = true;
        }
        else{
          ElMessage.error(this.mensajeErrorDD);    
        }
    },
    FlujoInfoContactoPJuridica(){
        this.validacionContacto.$validate();
        if (!this.validacionContacto.$error) {
          this.GenerarGuardadoParcialPJuridica();
          this.ModificarPasosIfJuridico();
          this.mostrarCliente = true;
          this.store.dispatch("Avanzar");
        }
        else{
            ElMessage.error(this.mensajeErrorDD)    
        }
    },
    FlujoInfoPEPPNatural(){
        if (this.mostrarPEP) {
          this.validacionPep.$validate();
          if (!this.validacionPep.$error) {
            this.GenerarGuardadoParcialPNatural();
            this.RegistraPep = true;
            this.AvanzarCliente();
          }
          else{
            ElMessage.error(this.mensajeErrorDD)
          }
        } else {
          this.AvanzarCliente();
        }
    },
    FlujoInfoClientePNatural(){
        this.validacionCliente.$validate();
        if (!this.validacionCliente.$error) {
          if (this.RegistraPep || this.clienteRef.objetoCliente.ActivosVirtuales === "Si") {
           this.GenerarGuardadoParcialPNatural();
            this.store.dispatch("Avanzar");
          } else {
            this.store.dispatch("CargarInformacionCliente", this.clienteRef.objetoCliente);
            this.AvanzarCompromisosIfNoFinanciera();
            this.RegistrarGuardadoParcial();
          }      
        }
        else{
            ElMessage.error(this.mensajeErrorDD)
        }
    },
    FlujoInfoClientePJuridica(){
        this.validacionCliente.$validate();
        if (!this.validacionCliente.$error) {
          this.GenerarGuardadoParcialPJuridica();
          this.store.dispatch("Avanzar");
        }
        else{
           ElMessage.error(this.mensajeErrorDD);
        }
    },
    FlujoInfoLegalPJuridica(){
      this.validacionInfoRepresentanteTitular.$validate();
        this.validacionInfoRepresentanteSuplente.$validate();
        if (this.mostrarSeccionPep) {
          this.validacionInfoRepresentantePep.$validate();
          if (!this.validacionInfoRepresentanteTitular.$error && !this.validacionInfoRepresentanteSuplente.$error && !this.validacionInfoRepresentantePep.$error) {
            this.GenerarGuardadoParcialPJuridica();
            this.AccionistasDialogVisible = true;
          }
          else
          {
            ElMessage.error(this.mensajeErrorDD);
          }
        } else {
          if (!this.validacionInfoRepresentanteTitular.$error && !this.validacionInfoRepresentanteSuplente.$error) {
            this.GenerarGuardadoParcialPJuridica();
            this.AccionistasDialogVisible = true;
          }
          else
          {
              ElMessage.error(this.mensajeErrorDD);
          }
        }
    },
    FlujoAccNaturalIfPEP(){
        this.validacionInfoPepAccionista_Natural.$validate();
          if (!this.validacionInfoAccionista_Natural.$error && !this.validacionInfoPepAccionista_Natural.$error) {
            this.GenerarGuardadoParcialPJuridica();
            if (this.radioAccionistas === 2) {
              this.store.dispatch("Avanzar");
            } 
            else {
              this.mostrarAccionistasNaturales = false;
              this.mostrarAccionistasJuridicos = true;
            }
          }
          else{
            ElMessage.error(this.mensajeErrorDD)
            }
    },
    FlujoAccNaturalIfNoPEP(){
        if (!this.validacionInfoAccionista_Natural.$error) {
            this.GenerarGuardadoParcialPJuridica();
            if (this.radioAccionistas === 2) {
                if(this.mostrarSeccionPep || this.clienteRef.objetoCliente.ActivosVirtuales === "Si")
                {
                    this.store.dispatch("Avanzar");
                }
                else
                {
                    this.store.dispatch("AvanzarDosPasos");
                    this.LimpiaInfoFinanciera();
                }         
            } 
            else {
              this.mostrarAccionistasNaturales = false;
              this.mostrarAccionistasJuridicos = true;
            }
          }
          else{
            ElMessage.error(this.mensajeErrorDD)
            }
    },
    FlujoAccJuridicoIfPEP(){
        this.validacionInfoPepAccionista_Juridico.$validate();
            if(!this.validacionInfoAccionista_Juridico.$error && !this.validacionInfoBeneficiarioAccionista_Juridico.$error && !this.validacionInfoPepAccionista_Juridico.$error)
            {             
                this.GenerarGuardadoParcialPJuridica();          
                this.store.dispatch("Avanzar");                                               
            }
            else{
                ElMessage.error(this.mensajeErrorDD)
            }
    },
    FlujoAccJuridicoIfNoPEP(){
        if (!this.validacionInfoAccionista_Juridico.$error && !this.validacionInfoBeneficiarioAccionista_Juridico.$error) {
            this.GenerarGuardadoParcialPJuridica();
            if(this.mostrarSeccionPep || this.mostrarSeccionPepAN || this.clienteRef.objetoCliente.ActivosVirtuales === "Si")
                {
                    this.store.dispatch("Avanzar");
                }
                else
                {
                    this.store.dispatch("AvanzarDosPasos");
                    this.LimpiaInfoFinanciera();
                }
            }
            else{
                ElMessage.error(this.mensajeErrorDD)
            }
    },
    AvanzarCompromisosIfNoFinanciera() {
      this.store.dispatch("AvanzarDosPasos");
      this.LimpiaInfoFinanciera();     
    },
    CargarInfoLegal() {
      this.store.dispatch(
        "CargarInformacionRepresentanteTitular",
        this.RepresentanteTitularRef.objetoInfoRepresentanteTitular
      );
      this.store.dispatch(
        "CargarInformacionRepresentanteSuplente",
        this.RepresentanteSuplenteRef.objetoInfoRepresentanteSuplente
      );
      this.store.dispatch(
        "CargarInformacionRepresentantePep",
        this.RepresentantePepRef.objetoInfoRepresentantePep
      );
    },
    CargarInfoAccionistaNatural() {
      this.store.dispatch(
        "CargarInformacionAccionistaNatural",
        this.InfoAccionista_NaturalRef.objetoInfoAccionista_Natural
      );
      this.store.dispatch(
        "CargarInformacionPepAccionistaNatural",
        this.InfoPepAccionista_NaturalRef.objetoInfoPepAccionista_Natural
      );
    },
    CargarInfoAccionistaJuridico(){
        this.store.dispatch(
        "CargarInformacionAccionistaJuridico",
        this.InfoAccionista_JuridicoRef.objetoInfoAccionista_Juridico
      );
      this.store.dispatch(
        "CargarInformacionBeneficiarioAccionistaJuridico",
        this.InfoBeneficiarioAccionista_JuridicoRef.objetoInfoBeneficiarioAccionista_Juridico
      );
      this.store.dispatch(
        "CargarInformacionPepAccionistaJuridico",
        this.InfoPepAccionista_JuridicoRef.objetoInfoPepAccionista_Juridico
      );
    },
    MostrarSeccionPepRT(Result) {
      this.EstadoPEPRT = Result;
      this.ComparaEstadoPep();
    },
    MostrarSeccionPepRS(Result) {
      this.EstadoPEPRS = Result;
      this.ComparaEstadoPep();
    },
    ComparaEstadoPep() {
      if (this.EstadoPEPRT || this.EstadoPEPRS) {
        this.mostrarSeccionPep = true;
      } else {
        this.mostrarSeccionPep = false;
        this.RepresentantePepRef.reiniciarObjetoInfoPepRepresentante()
      }
    },
    AvanzarAccionistas(seleccionAccionistas) {
      if (seleccionAccionistas === 1) {
        if (
          this.mostrarSeccionPep || this.clienteRef.objetoCliente.ActivosVirtuales === "Si"
        ) {
          this.AccionistasDialogVisible = false;
          this.store.dispatch("AvanzarDosPasos");
        } else {
          this.AccionistasDialogVisible = false;
          this.LimpiaInfoFinanciera();
          this.store.dispatch("AvanzarTresPasos");
        }
        this.LimpiarInfoAccionistas();
      } else {
        if (seleccionAccionistas === 2 || seleccionAccionistas === 4) {
          this.AccionistasDialogVisible = false;
          this.mostrarAccionistasNaturales = true;
          this.mostrarAccionistasJuridicos = false;
          this.store.dispatch("Avanzar");
          if(seleccionAccionistas === 2)
          {
              this.LimpiarInfoAccionistasJuridicos();
          }
        } else {
          if (seleccionAccionistas === 3) {
            this.AccionistasDialogVisible = false;
            this.store.dispatch("Avanzar");
            this.mostrarAccionistasNaturales = false;
            this.mostrarAccionistasJuridicos = true;
            this.LimpiarInfoAccionistasNaturales();
          }
        }
      }
    },
    MostrarSeccionPepAN(Result) {
      this.mostrarSeccionPepAN = Result;
      if(!this.mostrarSeccionPepAN){
          this.InfoPepAccionista_NaturalRef.reiniciarObjetoInfoPepAccionista_Natural()
      }
    },
    MostrarSeccionPepAJ(Result) {
      this.mostrarSeccionPepAJ = Result;
      if(!this.mostrarSeccionPepAJ){
          this.InfoPepAccionista_JuridicoRef.reiniciarObjetoInfoPepAccionista_Juridico()
      }
      
    },
    LimpiarInfoAccionistas(){
        this.InfoAccionista_NaturalRef.reiniciarObjetoInfoAccionista_Natural();
        this.InfoPepAccionista_NaturalRef.reiniciarObjetoInfoPepAccionista_Natural();
        this.InfoAccionista_JuridicoRef.reiniciarObjetoInfoAccionista_Juridico();
        this.InfoBeneficiarioAccionista_JuridicoRef.reiniciarObjetoInfoBeneficiarioAccionista_Juridico();
        this.InfoPepAccionista_JuridicoRef.reiniciarObjetoInfoPepAccionista_Juridico();
        this.mostrarSeccionPepAN = false;
        this.mostrarSeccionPepAJ = false;
    },
    LimpiarInfoAccionistasNaturales(){
        this.InfoAccionista_NaturalRef.reiniciarObjetoInfoAccionista_Natural();
        this.InfoPepAccionista_NaturalRef.reiniciarObjetoInfoPepAccionista_Natural();
        this.mostrarSeccionPepAN = false;
    },
    LimpiarInfoAccionistasJuridicos(){
        this.InfoAccionista_JuridicoRef.reiniciarObjetoInfoAccionista_Juridico();
        this.InfoBeneficiarioAccionista_JuridicoRef.reiniciarObjetoInfoBeneficiarioAccionista_Juridico();
        this.InfoPepAccionista_JuridicoRef.reiniciarObjetoInfoPepAccionista_Juridico();
        this.mostrarSeccionPepAJ = false;
    },
    LimpiaInfoFinanciera(){
        this.store.dispatch("EliminarInformacionFinanciera");
        this.AdicionalRef.objetoArchivos.EstadosFinancieros.InformacionFinanciera = undefined;
        this.FinancieraRef.reiniciarObjetoFinanciera();
    },
    //#region GuardadoParcial
    RegistrarGuardadoParcial(){
        let params = {
              TokenDD: this.store.state.tokenDD,
              TipoIdentificacion: this.contactoRef.objetoContacto.Data.TipoDocumento,
              NumeroIdentificacion: this.contactoRef.objetoContacto.Data.No_Identificacion,
              IdProd: localStorage.getItem("IdProd"),
              EntradaGuardadoParcial: JSON.stringify(this.store.state.ddModule.debidaDiligencia)
            };                          
            this.store.dispatch('RegistrarGuardadoParcial', params)
            .then((data) => {  
              if(data.status == 204)
                {
                  console.log("Guardado parcial realizado exitosamente");
                }           
            })
            .catch(error => {
              console.log(error);
              this.RedireccionEnErrorRef(error);  
            })         
    },
    EliminarGuardadoParcial(){
          let params = {
              TokenDD: this.store.state.tokenDD,
              TipoIdentificacion: this.contactoRef.objetoContacto.Data.TipoDocumento,
              NumeroIdentificacion: this.contactoRef.objetoContacto.Data.No_Identificacion,
            };
            this.store.dispatch('EliminarGuardadoParcial', params)
            .then((data) => {  
              if(data.status == 204)
                {
                  console.log("Guardado parcial eliminado exitosamente");
                }           
            })
            .catch(error => {
              console.log(error);
            })
    },
    
    CargarInfoPNaturalenEsquemaJson(){
      this.store.dispatch("CargarInformacionContacto",this.contactoRef.objetoContacto.Data);
      this.store.dispatch("CargarInformacionCliente", this.clienteRef.objetoCliente);
      this.store.dispatch("CargarInformacionPep", this.pepRef.objetoPep);
      this.CargarInfoFinancieraEsquemaJson();

    },
    CargarInfoPJuridicaenEsquemaJson(){
      this.store.dispatch("CargarInformacionContacto",this.contactoRef.objetoContacto.Data);
      this.store.dispatch("CargarInformacionCliente", this.clienteRef.objetoCliente);
      this.CargarInfoLegal();
      this.CargarInfoAccionistaNatural();
      this.CargarInfoAccionistaJuridico();
      this.CargarInfoFinancieraEsquemaJson();

    },
    GenerarGuardadoParcial()
    {
      if(this.contactoRef.objetoContacto.Data.TipoPersona != "Jurídica")
      {
        this.GenerarGuardadoParcialPNatural();
      }
      else
      if(this.contactoRef.objetoContacto.Data.TipoPersona == "Jurídica")
      {
        this.GenerarGuardadoParcialPJuridica();
      }
    },
    GenerarGuardadoParcialPNatural(){
      this.CargarInfoPNaturalenEsquemaJson();
      this.RegistrarGuardadoParcial();
    },
    GenerarGuardadoParcialPJuridica(){
      this.CargarInfoPJuridicaenEsquemaJson();
      this.RegistrarGuardadoParcial();
    },
    //#endregion
    CargarInfoFinancieraEsquemaJson()
    {
      this.ConvertirNullEnCerosFinanciera();
      let CamposMonedaFinanciera = Object.fromEntries(Object.entries(this.FinancieraRef.objetoFinanciera).filter(([key]) => !key.includes('Descripcion')));
      let validaNulidadCM = Object.values(CamposMonedaFinanciera).every((x) => x === null || x === 0)
      let CamposTextoFinanciera = Object.fromEntries(Object.entries(this.FinancieraRef.objetoFinanciera).filter(([key]) => key.includes('Descripcion')));
      let validaNulidadCT = Object.values(CamposTextoFinanciera).every((x) => x === undefined || x === "" || x === null)
      if(!validaNulidadCM || !validaNulidadCT)
      {
        this.store.dispatch("CargarInformacionFinanciera",this.FinancieraRef.objetoFinanciera);
      }   
    },
    ConvertirNullEnCerosFinanciera()
    {
      if(this.FinancieraRef.objetoFinanciera.VentasIngresosOperacionales == null)
      {
        this.FinancieraRef.objetoFinanciera.VentasIngresosOperacionales = 0
      }
      if(this.FinancieraRef.objetoFinanciera.TotalActivos == null)
      {
        this.FinancieraRef.objetoFinanciera.TotalActivos = 0
      }
      if(this.FinancieraRef.objetoFinanciera.Egresos == null)
      {
        this.FinancieraRef.objetoFinanciera.Egresos = 0
      }
      if(this.FinancieraRef.objetoFinanciera.IngresosNoOperacionales == null)
      {
        this.FinancieraRef.objetoFinanciera.IngresosNoOperacionales = 0
      }
      if(this.FinancieraRef.objetoFinanciera.OtrosActivos == null)
      {
        this.FinancieraRef.objetoFinanciera.OtrosActivos = 0
      }
      if(this.FinancieraRef.objetoFinanciera.TotalPasivos == null)
      {
        this.FinancieraRef.objetoFinanciera.TotalPasivos = 0
      }
      if(this.FinancieraRef.objetoFinanciera.TotalIngresos == null)
      {
        this.FinancieraRef.objetoFinanciera.TotalIngresos = 0
      }
      if(this.FinancieraRef.objetoFinanciera.ActivosTotales2 == null)
      {
        this.FinancieraRef.objetoFinanciera.ActivosTotales2 = 0
      }
      if(this.FinancieraRef.objetoFinanciera.GastosTotales == null)
      {
        this.FinancieraRef.objetoFinanciera.GastosTotales = 0
      }
      if(this.FinancieraRef.objetoFinanciera.GastosFinancieros == null)
      {
        this.FinancieraRef.objetoFinanciera.GastosFinancieros = 0
      }
    },
    ValidarDebidaDiligencia()
    {
        let ParametrosEmpleador = {
            TipoDocumento: this.contactoRef.objetoContacto.Data.TipoDocumento,
            NumeroDocumento: this.contactoRef.objetoContacto.Data.No_Identificacion,
            TokenDD: this.store.state.tokenDD
        }
        this.store.dispatch('ObtenerEstadoEmpleadorDD',ParametrosEmpleador).then(i=>{
           if(i.data==this.EstadoDebidaDiligencia.CreadaDD || 
             i.data==this.EstadoDebidaDiligencia.AprobadaDD || 
             i.data==this.EstadoDebidaDiligencia.RechazadaDD){         
               router.push({name:'StopDueDiligence'});
             }           
          }).catch(error => {
              console.log(error);
            })          
    }
  }
});
</script>