import { reactive } from 'vue'
import { required, helpers, minLength, maxLength, minValue, maxValue } from '@vuelidate/validators'
import GeneralRules from './GeneralRules';

class InfoAccionista_NaturalValidacion{

     public ObtenerReglasValidacion() {

         const objetoInfoAccionista_Natural = reactive({
            An: [{
                NombreAN: '',
                TipoIdAN: '',
                NumeroIdentificacionAN: '',
                ParticipacionCapitalAN: '',
                EsPEPAN: '',
                VinculosFamPEP_AN: ''
             }]
         });

         const requiredMessage = 'Campo obligatorio';
         
         const patternRule = (pattern: RegExp) => ({
            $validator: (value: string) => pattern.test(value),
            trigger: ['blur', 'change'],
            });

        const validarNumeroPorTipoId = helpers.withMessage
            ('No se admiten espacios ni caracteres especiales', 
                (value: string, {TipoIdAN}:{TipoIdAN:string}) => {
                    if(!value || !TipoIdAN) return false;
                        return GeneralRules.ValidateRegex(value,TipoIdAN);      
            });
        
        const validarTamanoNumero = helpers.withMessage
            ('El rango de caracteres no es admitido', 
                (value: string, {TipoIdAN}:{TipoIdAN:string}) => {
                    if(!value || !TipoIdAN) return false;
                        return GeneralRules.ValidateSize(value,TipoIdAN);      
            });
   
        const reglaCaracteresEspeciales = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ]+( [a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$/;
        const reglasPorcentaje = /^(?!0$)(100|[1-9]?\d)$/;

         const reglasValidacion = {
            An: {
                 $each: helpers.forEach({
                    NombreAN: {
                         required: helpers.withMessage(requiredMessage, required),
                         minLength: helpers.withMessage('El rango de caracteres admitidos en este campo es de 2 a 100',minLength(2)),  
                         maxLength: helpers.withMessage('El rango de caracteres admitidos en este campo es de 2 a 100',maxLength(100)),         
                         patternRuleCarecteres: helpers.withMessage('No se admiten espacios al inicio o al final ni caracteres especiales', patternRule(reglaCaracteresEspeciales))
                     },
                     TipoIdAN: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     NumeroIdentificacionAN: {
                         required: helpers.withMessage(requiredMessage, required),
                         validarNumeroPorTipoId,
                         validarTamanoNumero
                     },
                     ParticipacionCapitalAN: {
                        required: helpers.withMessage(requiredMessage, required),
                        minLength: helpers.withMessage('El rango permitido es de 1 a 100',patternRule(reglasPorcentaje)),  
                        patternRuleCarecteres: helpers.withMessage('No se admiten espacios ni caracteres especiales', patternRule(/^[0-9]*$/))
                    },
                     EsPEPAN: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     VinculosFamPEP_AN: {
                        required: helpers.withMessage(requiredMessage, required),
                    },
                 })
             }
         };

        return [objetoInfoAccionista_Natural, reglasValidacion] as const;
    }
}

export default InfoAccionista_NaturalValidacion;