import { reactive } from 'vue'
import { required, helpers, minLength, maxLength, requiredIf } from '@vuelidate/validators'
import GeneralRules from './GeneralRules';

class PepValidacion{

     public ObtenerReglasValidacion() {

         const objetoPep = reactive({
             campos: [{
                 Nombre: '',
                 TipoId: '',
                 NumeroIdentificacion: '',
                 TipoVinculo: '',
                 TipoPep: ''
             }]
         });

         const requiredMessage = 'Campo obligatorio';
         
                  
         const patternRule = (pattern: RegExp) => ({
            $validator: (value: string) => pattern.test(value),
            trigger: ['blur', 'change'],
            });

        const validarNumeroPorTipoId = helpers.withMessage
            ('No se admiten espacios ni caracteres especiales', 
                (value: string, {TipoId}:{TipoId:string}) => {
                    if(!value || !TipoId) return false;
                        return GeneralRules.ValidateRegex(value,TipoId);      
            });

         const validarTamanoNumero = helpers.withMessage
         ('El rango de caracteres no es admitido', 
             (value: string, {TipoId}:{TipoId:string}) => {
                 if(!value || !TipoId) return false;
                     return GeneralRules.ValidateSize(value,TipoId);      
         });

        const reglaCaracteresEspeciales = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ]+( [a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$/;

         const reglasValidacion = {
             campos: {
                 $each: helpers.forEach({
                     Nombre: {
                         required: helpers.withMessage(requiredMessage, required),
                         minLength: helpers.withMessage('El rango de caracteres admitidos en este campo es de 2 a 100',minLength(2)),  
                         maxLength: helpers.withMessage('El rango de caracteres admitidos en este campo es de 2 a 100',maxLength(100)),         
                         patternRuleCarecteres: helpers.withMessage('No se admiten espacios al inicio o al final ni caracteres especiales', patternRule(reglaCaracteresEspeciales))
                     },
                     TipoId: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     NumeroIdentificacion: {
                         required: helpers.withMessage(requiredMessage, required),
                         validarNumeroPorTipoId,
                         validarTamanoNumero
                     },
                     TipoVinculo: {
                        required: helpers.withMessage(requiredMessage, required),
                        maxLength: helpers.withMessage('El rango de caracteres en este campo es de 1 a 10',maxLength(10)),
                        patternRuleCarecteres: helpers.withMessage('No se admiten espacios ni caracteres especiales', patternRule(reglaCaracteresEspeciales))
                     },
                     TipoPep: {
                         required: helpers.withMessage(requiredMessage, required),
                     }
                 })
             }
         };

        return [objetoPep, reglasValidacion] as const;
    }
}

export default PepValidacion;
