<template>
    <el-form>
    <el-row :gutter="24">
      <el-col :span="24">
        <h1 class="section-text">Información complementaria PEP</h1>
      </el-col>
    </el-row>

    <el-row :gutter="24">
      <el-col :span="24">
        <p class="pep-text"> Incluir la siguiente información, nombre y número de identificación: </p>
        <p class="pep-text">
          <ol>
            <li> Los cónyuges o compañeros permanentes del PEP </li>
            <li> Los familiares de las PEP, hasta el segundo grado de consanguinidad, segundo de afinidad y primero civil. </li>
            <li> Los asociados de un PEP, cuando el PEP sea socio de, o esté asociado a, una persona jurídica y, además, sea propietario directa o indirectamente de una participación superior al 5% de la persona jurídica, o ejerza el control de la persona jurídica, en los términos del artículo 261 del Código de Comercio. </li>
          </ol>
        </p>
      </el-col>
    </el-row>

    <div class="pepInfoLegal">
    <el-row :gutter="24">
      <el-col :span="22">
        <el-table height="300" :data="objetoInfoPepAccionista_Juridico.PepAJ" :span="24">

          <el-table-column width="20%" >
              <template v-slot="scope">
                 <span><strong>{{scope.$index + 1}}</strong></span>
                 <div v-if="HabilitaCamposPepAJ" style="height:62px;"></div>
              </template>
          </el-table-column>

          <el-table-column class="section-text-color" label="Nombre del accionista*" width="220px;">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.NombreAJ_PEP" :class="[validacionInfoPepAccionista_Juridico.PepAJ.$each.$response.$errors[scope.$index].NombreAJ_PEP.length ? 'border-invalid' : 'border-valid']" :disabled="!HabilitaCamposPepAJ" @change="ValidarCamposFila(scope.$index)"/>
              <div v-if="HabilitaCamposPepAJ">
                <div style="height:62px;" v-show="!validacionInfoPepAccionista_Juridico.PepAJ.$each.$response.$errors[scope.$index].NombreAJ_PEP.length"></div>
              <el-form-item v-if="validacionInfoPepAccionista_Juridico.PepAJ.$each.$response.$errors[scope.$index].NombreAJ_PEP[0]" :key="validacionInfoPepAccionista_Juridico.PepAJ.$each.$response.$errors[scope.$index].NombreAJ_PEP[0].$message" class="section-error-message">
                {{ validacionInfoPepAccionista_Juridico.PepAJ.$each.$response.$errors[scope.$index].NombreAJ_PEP[0].$message }}
              </el-form-item>
              </div>
            </template>
          </el-table-column>

          <el-table-column class="section-text-color" label="Nombre de PEP*" width="220px;">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.NombrePEP" :class="[validacionInfoPepAccionista_Juridico.PepAJ.$each.$response.$errors[scope.$index].NombrePEP.length ? 'border-invalid' : 'border-valid']" :disabled="!HabilitaCamposPepAJ" @change="ValidarCamposFila(scope.$index)"/>
              <div v-if="HabilitaCamposPepAJ">
                <div style="height:62px;" v-show="!validacionInfoPepAccionista_Juridico.PepAJ.$each.$response.$errors[scope.$index].NombrePEP.length"></div>
              <el-form-item v-if="validacionInfoPepAccionista_Juridico.PepAJ.$each.$response.$errors[scope.$index].NombrePEP[0]" :key="validacionInfoPepAccionista_Juridico.PepAJ.$each.$response.$errors[scope.$index].NombrePEP[0].$message" class="section-error-message">
                {{ validacionInfoPepAccionista_Juridico.PepAJ.$each.$response.$errors[scope.$index].NombrePEP[0].$message }}
              </el-form-item>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Tipo ID*" width="135px;">
            <template v-slot="scope">
              <el-select v-model="scope.row.TipoIdPEPAJ" placeholder="Seleccionar" :class="[validacionInfoPepAccionista_Juridico.PepAJ.$each.$response.$errors[scope.$index].TipoIdPEPAJ.length ? 'border-invalid' : 'border-valid']" :disabled="!HabilitaCamposPepAJ" @change="ValidarCamposFila(scope.$index)">
               <el-option 
                v-for="item in parametricas.tipoDeIdentificacion && parametricas.tipoDeIdentificacion
                .filter(t => t.valor !== 'NIT' && t.valor !== 'TI')" 
                :key="item.codigo" 
                :label="item.valor" 
                :value="item.valor" />   
              </el-select>
              <div v-if="HabilitaCamposPepAJ">
                <div style="height:62px;" v-show="!validacionInfoPepAccionista_Juridico.PepAJ.$each.$response.$errors[scope.$index].TipoIdPEPAJ.length"></div>
              <el-form-item v-for="error in validacionInfoPepAccionista_Juridico.PepAJ.$each.$response.$errors[scope.$index].TipoIdPEPAJ" :key="error" class="section-error-message">
                {{ error.$message }}
              </el-form-item>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="N° ID*" width="180px;">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.NumeroIdentificacionPEPAJ" :class="[validacionInfoPepAccionista_Juridico.PepAJ.$each.$response.$errors[scope.$index].NumeroIdentificacionPEPAJ.length ? 'border-invalid' : 'border-valid']" :disabled="!HabilitaCamposPepAJ" @change="ValidarCamposFila(scope.$index)"/>
              <div v-if="HabilitaCamposPepAJ">
                <div style="height:62px;" v-show="!validacionInfoPepAccionista_Juridico.PepAJ.$each.$response.$errors[scope.$index].NumeroIdentificacionPEPAJ.length"></div>
              <el-form-item v-if="validacionInfoPepAccionista_Juridico.PepAJ.$each.$response.$errors[scope.$index].NumeroIdentificacionPEPAJ[0]" :key="validacionInfoPepAccionista_Juridico.PepAJ.$each.$response.$errors[scope.$index].NumeroIdentificacionPEPAJ[0].$message" class="section-error-message">
                {{ validacionInfoPepAccionista_Juridico.PepAJ.$each.$response.$errors[scope.$index].NumeroIdentificacionPEPAJ[0].$message }}
              </el-form-item>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Tipo de vínculo*" width="180px;">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.TipoVinculoPEPAJ" :class="[validacionInfoPepAccionista_Juridico.PepAJ.$each.$response.$errors[scope.$index].TipoVinculoPEPAJ.length ? 'border-invalid' : 'border-valid']" :disabled="!HabilitaCamposPepAJ" @change="ValidarCamposFila(scope.$index)"/>
              <div v-if="HabilitaCamposPepAJ">
                <div style="height:62px;" v-show="!validacionInfoPepAccionista_Juridico.PepAJ.$each.$response.$errors[scope.$index].TipoVinculoPEPAJ.length"></div>
                <el-form-item v-if="validacionInfoPepAccionista_Juridico.PepAJ.$each.$response.$errors[scope.$index].TipoVinculoPEPAJ[0]" :key="validacionInfoPepAccionista_Juridico.PepAJ.$each.$response.$errors[scope.$index].TipoVinculoPEPAJ[0].$message" class="section-error-message">
                {{ validacionInfoPepAccionista_Juridico.PepAJ.$each.$response.$errors[scope.$index].TipoVinculoPEPAJ[0].$message }}
              </el-form-item>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Tipo de PEP*" width="135px;">
            <template v-slot="scope">
              <el-select v-model="scope.row.TipoPEPAJ" placeholder="Seleccionar" :class="[validacionInfoPepAccionista_Juridico.PepAJ.$each.$response.$errors[scope.$index].TipoPEPAJ.length ? 'border-invalid' : 'border-valid']" :disabled="!HabilitaCamposPepAJ" @change="ValidarCamposFila(scope.$index)">
                <el-option v-for="item in parametricas.tipoDePep" :key="item.codigo" :label="item.valor" :value="item.valor" />
              </el-select>
              <div v-if="HabilitaCamposPepAJ">
                <div style="height:62px;" v-show="!validacionInfoPepAccionista_Juridico.PepAJ.$each.$response.$errors[scope.$index].TipoPEPAJ.length"></div>
              <el-form-item v-for="error in validacionInfoPepAccionista_Juridico.PepAJ.$each.$response.$errors[scope.$index].TipoPEPAJ" :key="error" class="section-error-message">
                {{ error.$message }}
              </el-form-item>
              </div>
            </template>
          </el-table-column>

          <el-table-column width="50">
            <template v-slot="scope">
              <el-button v-if="scope.$index > 0"  circle class="dinamic-form-button" size="small" type="success" @click="EliminarFilaPEPAJ(scope.$index)">
                <font-awesome-icon
                :icon="['fas', 'minus']"
                />
              </el-button>
                <div style="height:62px;"></div>
            </template>
          </el-table-column>

        </el-table>
      </el-col>

      <el-col :span="2">
        <el-button circle class="dinamic-form-button" size="small" type="success" @click="AgregarFilaPEPAJ" :disabled="!HabilitaCamposPepAJ">
          <font-awesome-icon
                :icon="['fas', 'plus']"
                />
        </el-button>
      </el-col>
    </el-row>
    </div>
</el-form>
</template>
<script>

import useVuelidate from '@vuelidate/core';
import { defineComponent, onMounted } from 'vue';
import InfoPepAccionista_JuridicoValidacion from '../../validation/InfoPepAccionista_Juridico';
import '../../assets/sass/components/DueDiligence/_legal.scss';

export default defineComponent({
    props: {
        scopeInfoPepAccionista_Juridico: String,
        HabilitaCamposPepAJ: Boolean,
        parametricas: {},
        infoFormularioPepAccJuridico: {}
    },
    setup(props) {
        
        let [objetoInfoPepAccionista_Juridico, reglasValidacion] = new InfoPepAccionista_JuridicoValidacion().ObtenerReglasValidacion();


        const AgregarFilaPEPAJ = () => {

            objetoInfoPepAccionista_Juridico.PepAJ.push({
                NombreAJ_PEP: '',
                NombrePEP: '',
                TipoIdPEPAJ: '',
                NumeroIdentificacionPEPAJ: '',
                TipoVinculoPEPAJ: '',
                TipoPEPAJ: ''
            }); 
        }

        const EliminarFilaPEPAJ = (index) => {
            objetoInfoPepAccionista_Juridico.PepAJ.splice(index, 1);
        }

        const reiniciarObjetoInfoPepAccionista_Juridico = () => {
          objetoInfoPepAccionista_Juridico.PepAJ = [];
          AgregarFilaPEPAJ();
        }
        const validacionInfoPepAccionista_Juridico = useVuelidate(reglasValidacion, objetoInfoPepAccionista_Juridico, { $scope: props.scopeInfoPepAccionista_Juridico })

        const tiposIdentificacion = [{
            value: "2",
            label: "CC",
          },
          {
            value: "3",
            label: "CE",
          },
          {
            value: "5",
            label: "PAS",
          },
          {
            value: "6",
            label: "PE",
          }
        ]

        onMounted(() => {
          let i;
          
        if(props.infoFormularioPepAccJuridico != "No se encuentra diligenciamiento del formulario para el empleador consultado" && props.infoFormularioPepAccJuridico.pepAJ != null)
        {      
          if((props.infoFormularioPepAccJuridico.pepAJ.length > 0) && (Object.values(props.infoFormularioPepAccJuridico.pepAJ[0]).some(x => x !== null && x !== '' && x !== undefined)))
          {
            objetoInfoPepAccionista_Juridico.PepAJ.splice(0, 1);
          }

             for(i = 0; i < props.infoFormularioPepAccJuridico.pepAJ.length; i++)
            {
                if(Object.values(props.infoFormularioPepAccJuridico.pepAJ[i]).some(x => x !== null && x !== '' && x !== undefined))
                {
                    objetoInfoPepAccionista_Juridico.PepAJ.push({
                        NombreAJ_PEP: props.infoFormularioPepAccJuridico.pepAJ[i].nombreAJ_PEP,
                        NombrePEP: props.infoFormularioPepAccJuridico.pepAJ[i].nombrePEP,
                        TipoIdPEPAJ: props.infoFormularioPepAccJuridico.pepAJ[i].tipoIdPEPAJ,
                        NumeroIdentificacionPEPAJ: props.infoFormularioPepAccJuridico.pepAJ[i].numeroIdentificacionPEPAJ,
                        TipoVinculoPEPAJ: props.infoFormularioPepAccJuridico.pepAJ[i].tipoVinculoPEPAJ,
                        TipoPEPAJ: props.infoFormularioPepAccJuridico.pepAJ[i].tipoPEPAJ
                    }); 
                }             
            }
        }
      })


        return { 
          AgregarFilaPEPAJ, 
          EliminarFilaPEPAJ, 
          validacionInfoPepAccionista_Juridico, objetoInfoPepAccionista_Juridico, reiniciarObjetoInfoPepAccionista_Juridico,
          tiposIdentificacion
        }
    },
    methods:{
        ValidarCamposFila(index)
        {
          let FilaLlena = !Object.values(this.objetoInfoPepAccionista_Juridico.PepAJ[index]).some(x => x === null || x === '' || x === undefined)
          if(FilaLlena)
          {
            this.$emit('GenerarGuardadoParcial');
          }
        }
    }
})

</script>

