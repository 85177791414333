import { ref, reactive } from 'vue'
import { required, helpers, minLength, maxLength } from '@vuelidate/validators'
import GeneralRules from './GeneralRules';

class InfoRepresentanteTitularValidacion{

     public ObtenerReglasValidacion() {

         const objetoInfoRepresentanteTitular = reactive({
             RLTitular: [{
                NombreRLTitular: '',
                TipoIdRLTitular: '',
                NumeroIdentificacionRLTitular: '',
                EsPEPRLTitular: '',
                VinculosFamPEP_RLTitular: ''
             }]
         });

         const requiredMessage = 'Campo obligatorio';
         const messageNumeroId = ref('');
        const patternRule = (pattern: RegExp) => ({
            $validator: (value: string) => pattern.test(value),
            trigger: ['blur', 'change'],
            });
        
        const validarNumeroPorTipoId = helpers.withMessage
        ('No se admiten espacios ni caracteres especiales', 
            (value: string, {TipoIdRLTitular}:{TipoIdRLTitular:string}) => {
                if(!value || !TipoIdRLTitular) return false;
                    return GeneralRules.ValidateRegex(value,TipoIdRLTitular);      
        });

        const validarTamanoNumero = helpers.withMessage
        ('El rango de caracteres no es admitido', 
            (value: string, {TipoIdRLTitular}:{TipoIdRLTitular:string}) => {
                if(!value || !TipoIdRLTitular) return false;
                    return GeneralRules.ValidateSize(value,TipoIdRLTitular);      
        });

        const reglaCaracteresEspeciales = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ]+( [a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$/;

         const reglasValidacion = {
            RLTitular: {
                 $each: helpers.forEach({
                    NombreRLTitular: {
                        required: helpers.withMessage(requiredMessage, required),
                        minLength: helpers.withMessage('El rango de caracteres admitidos en este campo es de 2 a 100',minLength(2)),  
                        maxLength: helpers.withMessage('El rango de caracteres admitidos en este campo es de 2 a 100',maxLength(100)),         
                        patternRuleCarecteres: helpers.withMessage('No se admiten espacios al inicio o al final ni caracteres especiales', patternRule(reglaCaracteresEspeciales)),
                     },
                     TipoIdRLTitular: {
                        required: helpers.withMessage(requiredMessage, required),
                    },
                     NumeroIdentificacionRLTitular: {
                         required: helpers.withMessage(requiredMessage, required),
                         validarNumeroPorTipoId,
                         validarTamanoNumero
                            
                     },
                     EsPEPRLTitular: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     VinculosFamPEP_RLTitular: {
                        required: helpers.withMessage(requiredMessage, required),
                    }
                 })
             }     
         };

        return [objetoInfoRepresentanteTitular, reglasValidacion] as const;
    }
}

export default InfoRepresentanteTitularValidacion;