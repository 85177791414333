import { reactive } from 'vue'
import { helpers, minLength, maxLength } from '@vuelidate/validators'
import GeneralRules from './GeneralRules';

class InfoRepresentanteSuplenteValidacion{

     public ObtenerReglasValidacion() {

         const objetoInfoRepresentanteSuplente = reactive({
             RLSuplente:[{
                NombreRLSuplente: '',              
                TipoIdRLSuplente: '',
                NumeroIdentificacionRLSuplente: '',
                EsPEPRLSuplente: '',
                VinculosFamPEP_RLSuplente: ''
             }]
         });
         
        const patternRule = (pattern: RegExp) => ({
            $validator: (value: string) => pattern.test(value),
            trigger: ['blur', 'change'],
            });

        const validarNumeroPorTipoId = helpers.withMessage
            ('No se admiten espacios ni caracteres especiales', 
                (value: string, {TipoIdRLSuplente}:{TipoIdRLSuplente:string}) => {
                    if (!value) return true;
                    if(!value || !TipoIdRLSuplente) return false;
                        return GeneralRules.ValidateRegex(value,TipoIdRLSuplente);      
            });

        const validarTamanoNumero = helpers.withMessage
            ('El rango de caracteres no es admitido', 
                (value: string, {TipoIdRLSuplente}:{TipoIdRLSuplente:string}) => {
                    if (!value) return true;
                    if(!value || !TipoIdRLSuplente) return false;
                        return GeneralRules.ValidateSize(value,TipoIdRLSuplente);      
            });   

        const reglaCaracteresEspeciales = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ]+( [a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$/;

         const reglasValidacion = {
            RLSuplente: {
                 $each: helpers.forEach({
                    NombreRLSuplente: {
                        minLength: helpers.withMessage(
                          'El rango de caracteres admitidos en este campo es de 2 a 100',
                          (value: string) => {
                            return value && value.length >= 2 || !value ? true : false; 
                          }
                        ),
                        maxLength: helpers.withMessage(
                          'El rango de caracteres admitidos en este campo es de 2 a 100',
                          (value: string) => {
                            return value && value.length <= 100 || !value ? true : false; 
                          }
                        ),
                        patternRuleCarecteres: helpers.withMessage(
                          'No se admiten espacios al inicio o al final ni caracteres especiales',
                          (value: string) => {
                            return value && reglaCaracteresEspeciales.test(value) || !value ? true : false; 
                          }
                        )
                      },
                     NumeroIdentificacionRLSuplente: {
                        validarNumeroPorTipoId,
                        validarTamanoNumero
                     }                
                })
             }     
         };

        return [objetoInfoRepresentanteSuplente,reglasValidacion] as const;
    }
}

export default InfoRepresentanteSuplenteValidacion;