import { Module } from 'vuex';
import axios from 'axios'
const DueDiligence = process.env.VUE_APP_Gateway_API+"DebidaDiligencia/";
const GuardadoParcial = process.env.VUE_APP_Gateway_API+"GuardadoParcial/";

const ddModule: Module<any, any> = {
  state: {
    step: 1,
    debidaDiligencia: {
      contacto: {},
      Pep: {},
      Cliente: {},
      Financiera: {},
      Legal: {
        RepLegalTitular:{},
        RepLegalSuplente:{},
        RepLegalPep:{}
      },
      AccionistaNatural:{
        AccNatural:{},
        PepAccNatural:{}
      },
      AccionistaJuridico:{
        AccJuridico:{},
        BeneficiarioJuridico:{},
        PepAccJuridico:{}
      },
      Compromisos: {}
    },
    parametricas:{},
    divipolas: {
      departamentos: Object,
      municipios: Object
    }
  },
  getters: {},
  mutations: {
    Siguiente(state) {
      state.step++;
    },
    SiguienteDosPasos(state) {
      state.step = state.step + 2;
    },
    SiguienteTresPasos(state){
      state.step = state.step + 3;
    },
    Anterior(state){
      state.step--;
    },
    AnteriorDosPasos(state) {
      state.step = state.step - 2;
    },
    AnteriorTresPasos(state){
      state.step = state.step - 3;
    },
    infoContacto(state, objetoContacto){
      state.debidaDiligencia.contacto = objetoContacto;
    },
    infoPep(state, objetoPep) {
      state.debidaDiligencia.Pep = objetoPep;
    },
    eliminarPEP(state) {
      state.debidaDiligencia.Pep = {}
    },
    infoCliente(state, objetoCliente) {
      state.debidaDiligencia.Cliente = objetoCliente
    },
    infoFinanciera(state, objetoFinanciera) {
      state.debidaDiligencia.Financiera = objetoFinanciera
    },
    eliminarFinanciera(state) {
      state.debidaDiligencia.Financiera = {}
    },
    infoRepresentanteTitular(state, objetoInfoRepresentanteTitular){
      state.debidaDiligencia.Legal.RepLegalTitular = objetoInfoRepresentanteTitular
    },
    infoRepresentanteSuplente(state, objetoInfoRepresentanteSuplente){
      state.debidaDiligencia.Legal.RepLegalSuplente = objetoInfoRepresentanteSuplente
    },
    infoRepresentantePep(state, objetoInfoRepresentantePep){
      state.debidaDiligencia.Legal.RepLegalPep = objetoInfoRepresentantePep
    },
    infoAccionistaNatural(state, objetoInfoAccionista_Natural){
      state.debidaDiligencia.AccionistaNatural.AccNatural = objetoInfoAccionista_Natural
    },
    infoPepAccionistaNatural(state, objetoInfoPepAccionista_Natural){
      state.debidaDiligencia.AccionistaNatural.PepAccNatural = objetoInfoPepAccionista_Natural
    },
    infoAccionistaJuridico(state, objetoInfoAccionista_Juridico){
      state.debidaDiligencia.AccionistaJuridico.AccJuridico = objetoInfoAccionista_Juridico
    },
    infoBeneficiarioAccionistaJuridico(state, objetoInfoBeneficiarioAccionista_Juridico){
      state.debidaDiligencia.AccionistaJuridico.BeneficiarioJuridico = objetoInfoBeneficiarioAccionista_Juridico
    },
    infoPepAccionistaJuridico(state, objetoInfoPepAccionista_Juridico){
      state.debidaDiligencia.AccionistaJuridico.PepAccJuridico = objetoInfoPepAccionista_Juridico
    },
    cargarParametricas(state, objetoParametricas){
      state.parametricas = objetoParametricas
    },
    infoCompromisos(state, objetoCompromisos){
      state.debidaDiligencia.Compromisos = objetoCompromisos
    },
    CargarDivipolas(state, objetoDivipola){
      state.divipolas = objetoDivipola
    },
  },
  actions: {
    Avanzar({
      commit
    }) {
      commit('Siguiente');
    },
    AvanzarDosPasos({
      commit
    }) {
      commit('SiguienteDosPasos')
    },
    AvanzarTresPasos({commit}){
      commit('SiguienteTresPasos')
    },
    Retroceder({commit}){
      commit('Anterior');
    },
    RetrocederDosPasos({
      commit
    }) {
      commit('AnteriorDosPasos')
    },
    RetrocederTresPasos({commit}){
      commit('AnteriorTresPasos')
    },
    CargarInformacionContacto({commit}, objetoContacto){
       commit('infoContacto', objetoContacto);
    },
    CargarInformacionPep({ commit }, objetoPep) {
      commit('infoPep', objetoPep);
    },
    EliminarInformacionPEP({ commit }) {
      commit('eliminarPEP');
    },
    CargarInformacionCliente({ commit }, objetoCliente) {
      commit('infoCliente', objetoCliente)
    },
    CargarInformacionFinanciera({ commit }, objetoFinanciera) {
      commit('infoFinanciera', objetoFinanciera)
    },
    EliminarInformacionFinanciera({ commit }) {
      commit('eliminarFinanciera');
    },
    CargarInformacionRepresentanteTitular({commit}, objetoInfoRepresentanteTitular){
      commit('infoRepresentanteTitular', objetoInfoRepresentanteTitular)
    },
    CargarInformacionRepresentanteSuplente({commit}, objetoInfoRepresentanteSuplente){
      commit('infoRepresentanteSuplente', objetoInfoRepresentanteSuplente)
    },
    CargarInformacionRepresentantePep({commit}, objetoInfoRepresentantePep){
      commit('infoRepresentantePep', objetoInfoRepresentantePep)
    },
    CargarInformacionAccionistaNatural({commit}, objetoInfoAccionista_Natural){
      commit('infoAccionistaNatural',objetoInfoAccionista_Natural)
    },
    CargarInformacionPepAccionistaNatural({commit}, objetoInfoPepAccionista_Natural){
      commit('infoPepAccionistaNatural',objetoInfoPepAccionista_Natural)
    },
    CargarInformacionAccionistaJuridico({commit}, objetoInfoAccionista_Juridico){
      commit('infoAccionistaJuridico',objetoInfoAccionista_Juridico)
    },
    CargarInformacionBeneficiarioAccionistaJuridico({commit}, objetoInfoBeneficiarioAccionista_Juridico){
      commit('infoBeneficiarioAccionistaJuridico',objetoInfoBeneficiarioAccionista_Juridico)
    },
    CargarInformacionPepAccionistaJuridico({commit}, objetoInfoPepAccionista_Juridico){
      commit('infoPepAccionistaJuridico',objetoInfoPepAccionista_Juridico)
    },
    CargarInformacionCompromisos({commit}, objetoCompromisos){
      commit('infoCompromisos',objetoCompromisos)
    },
    GetParametricasDebidaDiligencia({ commit },tokenDD) {
      let config = {
        headers: {
          TokenDD: tokenDD
        }
      }
      return new Promise((resolve, reject) => {
          axios.get(`${DueDiligence}ObtenerParametricas`,config)
              .then((res) => {
                commit('cargarParametricas', res.data);
                  resolve(res);
              })
              .catch((err) => {
                  reject(err);
              })
      })
    },
    
    RegistrarDebidaDiligencia({ commit }, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            TipoIdentificacion: data.TipoIdentificacion,
            NumeroIdentificacion: data.NumeroIdentificacion,
            RazonSocial: data.RazonSocial,
            IdProd: data.IdProd,
            TokenDD: data.TokenDD
          }
        }
        axios.post(`${DueDiligence}Registrar`, data.Archivos, config)
        .then((response)=>{
          resolve(response);
        })
        .catch(err=>{
          reject(err);
        });
      })
    },
    RegistrarGuardadoParcial({ commit }, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            TokenDD: data.TokenDD,
          }
        }
        axios.post(`${GuardadoParcial}RegistrarGuardadoParcial`, data, config)
        .then((response)=>{
          resolve(response);
        })
        .catch((err)=>{
          reject(err);
        });
      })
    },
    ObtenerGuardadoParcial({ commit },data) {
      let config = {
        headers: {
          TokenDD: data.TokenDD
        }
      }
      return new Promise((resolve, reject) => {  
        axios.get(GuardadoParcial+"ObtenerGuardadoParcial?tipoIdentificacion="+data.TipoIdentificacion+
        "&numeroIdentificacion="+data.NumeroIdentificacion, config)
        .then((response)=>{
          resolve(response);
        })
        .catch((err) => {
          reject(err)
        })       
      })
    },
    EliminarGuardadoParcial({ commit }, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            TokenDD: data.TokenDD,
          }
        }
        axios.post(`${GuardadoParcial}EliminarGuardadoParcial`, data, config)
        .then((response)=>{
          resolve(response);
        })
        .catch(error=>{
          reject(error.request.response);
        });
      })
    },
    ObtenerInfoFormularioDebidaDiligencia({ commit },data) {
      let config = {
        headers: {
          TokenDD: data.TokenDD
        }
      }
      return new Promise((resolve, reject) => {  
        axios.get(DueDiligence+"ObtenerInfoFormularioDebidaDiligencia?tipoIdentificacion="+data.TipoIdentificacion+
        "&numeroIdentificacion="+data.NumeroIdentificacion, config)
        .then((response)=>{
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        })       
      })
    }

  },
  modules: {}
}

export default ddModule;