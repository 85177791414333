import { computed, reactive } from 'vue'
import { required, helpers, email, requiredIf, alpha, minLength, maxLength} from '@vuelidate/validators'
import { useStore } from "vuex";
class ContactoValidacion {

    public ObtenerReglasValidacion() {

        const objetoContacto = reactive({
            Data:{
                TipoPersona: '',
                TipoDocumento: '',
                Nombre: '',
                No_Identificacion: '',
                ClaseSociedad: '',
                TipoEmpresa: '',
                OtroTipoEmpresa: '',
                Extension: '',
                Ciudad: '',
                Departamento: '',
                Pais: '',
                NombreContactoCliente: '',
                CargoContactoCliente: '',
                CorreoContactoCliente: '',           
                CelularContactoCliente: ''
            },
            HabilitadoParaDD: false
        
        })

        const store = useStore();

        const patternRule = (pattern: RegExp) => ({
            $validator: (value: string) => pattern.test(value),
            trigger: ['blur', 'change'],
            });

        const reglaCaracteresEspeciales = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ]+( [a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$/;

        const reglasValidacion = computed(() => {
            return {
                TipoPersona: {
                    required: helpers.withMessage('Seleccione tipo persona', required)
                },
                TipoDocumento: {
                    required: helpers.withMessage('Seleccione', required)
                },
                Nombre: 
                {
                    required: helpers.withMessage('Ingrese razón social', required)                  
                },
                No_Identificacion: {
                    required: helpers.withMessage('Ingrese número ID', required)
                },
                ClaseSociedad: {
                    required: helpers.withMessage('Seleccione clase sociedad', required)
                },
                TipoEmpresa: {
                    required: helpers.withMessage('Seleccione tipo empresa', required)
                },
                OtroTipoEmpresa: {
                    required: helpers.withMessage('Ingrese tipo empresa', requiredIf(objetoContacto.Data.TipoEmpresa === "Otra"))
                },
                Pais: {
                    required: helpers.withMessage('Seleccione país', required)
                },
                Departamento: {
                    required: helpers.withMessage('Seleccione departamento', requiredIf(objetoContacto.Data.Pais === "COLOMBIA"))
                },
                Ciudad: {
                    required: helpers.withMessage('Seleccione ciudad', requiredIf(objetoContacto.Data.Pais === "COLOMBIA"))
                },
                CorreoContactoCliente: {
                    email: helpers.withMessage("Ingrese un email válido", email),
                    required: helpers.withMessage("El campo email es obligatorio", required)
                },
                NombreContactoCliente: {
                    required: helpers.withMessage('Ingrese Nombre de contacto', required), 
                    minLength: helpers.withMessage('El rango de caracteres admitidos en este campo es de 2 a 100',minLength(2)),  
                    maxLength: helpers.withMessage('El rango de caracteres admitidos en este campo es de 2 a 100',maxLength(100)),         
                    patternRuleCarecteres: helpers.withMessage('No se admiten espacios al inicio o al final ni caracteres especiales', patternRule(reglaCaracteresEspeciales))
                },
                CelularContactoCliente: {
                    required: helpers.withMessage('Ingrese número de Celular', required),
                    minLength: helpers.withMessage('Ingresa al menos 10 caracteres',minLength(10)),  
                    maxLength: helpers.withMessage('Ingresa máximo 10 caracteres',maxLength(10)),         
                    patternRuleCarecteres: helpers.withMessage('El campo recibe únicamente números celulares', patternRule(/^3\d*$/))
                },
                CargoContactoCliente: {
                    required: helpers.withMessage('Ingrese cargo del contacto', required),
                    minLength: helpers.withMessage('El rango de caracteres admitidos en este campo es de 2 a 100',minLength(2)),  
                    maxLength: helpers.withMessage('El rango de caracteres admitidos en este campo es de 2 a 100',maxLength(100)),         
                    patternRuleCarecteres: helpers.withMessage('No se admiten espacios al inicio o al final ni caracteres especiales', patternRule(reglaCaracteresEspeciales))                
                }
            }
        })

        return [objetoContacto, reglasValidacion] as const;
    }
}

export default ContactoValidacion;
