<template>
  <el-form id="dinamic-form" class="pep greenItem">
    <el-row :gutter="24">
      <el-col :span="24">
        <h1 class="section-text">Información PEP</h1>
      </el-col>
    </el-row>

    <el-row :gutter="24">
      <el-col :span="24">
        <p class="pep-text">
          <strong class="section-text">*PEP</strong> es la Persona Expuesta Políticamente, igualmente se catalogan como PEP las personas que tengan una sociedad conyugal, de hecho, o derecho con las personas expuestas políticamente nacionales o extranjeras, así como a sus familiares hasta el segundo grado de consanguinidad, segundo de afinidad y primero civil.
        </p>
      </el-col>
    </el-row>

    <el-row :gutter="24">
      <el-col :span="24">
        <p class="highlightedTextPep">
          <strong>En caso de que las filas no sean suficientes, agrega más filas en "
            <el-button circle class="dinamic-form-button" size="mini" type="success ">
              <font-awesome-icon
                :icon="['fas', 'plus']"
                />
            </el-button>"
          </strong>
        </p>
      </el-col>
    </el-row>

    <el-row :gutter="24">
      <el-col :span="24">
        <p class="highlightedTextPep">
          <strong>En caso de requerir eliminar alguna fila, da clic en "
            <el-button circle class="dinamic-form-button" size="mini" type="success ">
              <font-awesome-icon
                :icon="['fas', 'minus']"
                />
            </el-button> "
          </strong>
        </p>
      </el-col>
    </el-row>

    <el-row :gutter="24">
      <el-col :span="24">
        <p class="pep-text"> Incluir la siguiente información, nombre y número de identificación: </p>
        <p class="pep-text">
          <ol>
            <li> Los cónyuges o compañeros permanentes del PEP </li>
            <li> Los familiares de las PEP, hasta el segundo grado de consanguinidad, segundo de afinidad y primero civil. </li>
            <li> Los asociados de un PEP, cuando el PEP sea socio de, o esté asociado a, una persona jurídica y, además, sea propietario directa o indirectamente de una participación superior al 5% de la persona jurídica, o ejerza el control de la persona jurídica, en los términos del artículo 261 del Código de Comercio. </li>
          </ol>
        </p>
      </el-col>
    </el-row>

    <el-row :gutter="24">
      <el-col :span="22">
        <el-table height="400" :data="objetoPep.campos" :span="24">

          <el-table-column width="40%" >
              <template v-slot="scope">
                 <span><strong>{{scope.$index + 1}}</strong></span>
                 <div style="height:40px;"></div>
              </template>
          </el-table-column>

          <el-table-column class="section-text-color" label="Nombre de PEP" width="220px;">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.Nombre" :class="[validacionPep.campos.$each.$response.$errors[scope.$index].Nombre.length ? 'border-invalid' : 'border-valid']" @change="ValidarCamposFila(scope.$index)"/>
              <div style="height:40px;" v-show="!validacionPep.campos.$each.$response.$errors[scope.$index].Nombre.length"></div>
              <el-form-item v-if="validacionPep.campos.$each.$response.$errors[scope.$index].Nombre[0]" :key="validacionPep.campos.$each.$response.$errors[scope.$index].Nombre[0].$message" class="section-error-message">
                {{ validacionPep.campos.$each.$response.$errors[scope.$index].Nombre[0].$message }}
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column label="Tipo ID" width="140px;">
            <template v-slot="scope">
              <el-select v-model="scope.row.TipoId" placeholder="Seleccionar" :class="[validacionPep.campos.$each.$response.$errors[scope.$index].TipoId.length ? 'border-invalid' : 'border-valid']" @change="ValidarCamposFila(scope.$index)">
                <el-option 
                v-for="item in parametricas.tipoDeIdentificacion && parametricas.tipoDeIdentificacion
                .filter(t => t.valor !== 'NIT' && t.valor !== 'TI')" 
                :key="item.codigo" 
                :label="item.valor" 
                :value="item.valor" />
              </el-select>
              <div style="height:40px;" v-show="!validacionPep.campos.$each.$response.$errors[scope.$index].TipoId.length"></div>
              <el-form-item v-for="error in validacionPep.campos.$each.$response.$errors[scope.$index].TipoId" :key="error" class="section-error-message">
                {{ error.$message }}
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column label="N° de identificación" width="180px;">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.NumeroIdentificacion" :class="[validacionPep.campos.$each.$response.$errors[scope.$index].NumeroIdentificacion.length ? 'border-invalid' : 'border-valid']" @change="ValidarCamposFila(scope.$index)"/>
              <div style="height:40px;" v-show="!validacionPep.campos.$each.$response.$errors[scope.$index].NumeroIdentificacion.length"></div>
              <el-form-item v-if="validacionPep.campos.$each.$response.$errors[scope.$index].NumeroIdentificacion[0]" :key="validacionPep.campos.$each.$response.$errors[scope.$index].NumeroIdentificacion[0].$message" class="section-error-message">
                {{ validacionPep.campos.$each.$response.$errors[scope.$index].NumeroIdentificacion[0].$message }}
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column label="Tipo de vínculo" width="180px;">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.TipoVinculo" :class="[validacionPep.campos.$each.$response.$errors[scope.$index].TipoVinculo.length ? 'border-invalid' : 'border-valid']" @change="ValidarCamposFila(scope.$index)"/>
              <div style="height:40px;" v-show="!validacionPep.campos.$each.$response.$errors[scope.$index].TipoVinculo.length"></div>
              <el-form-item v-if="validacionPep.campos.$each.$response.$errors[scope.$index].TipoVinculo[0]" :key="validacionPep.campos.$each.$response.$errors[scope.$index].TipoVinculo[0].$message" class="section-error-message">
                {{ validacionPep.campos.$each.$response.$errors[scope.$index].TipoVinculo[0].$message }}
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column label="Tipo de PEP" width="150px;">
            <template v-slot="scope">
              <el-select v-model="scope.row.TipoPep" placeholder="Seleccionar" :class="[validacionPep.campos.$each.$response.$errors[scope.$index].TipoPep.length ? 'border-invalid' : 'border-valid']" @change="ValidarCamposFila(scope.$index)">
                <el-option v-for="item in parametricas.tipoDePep" :key="item.codigo" :label="item.valor" :value="item.valor" />
              </el-select>
              <div style="height:40px;" v-show="!validacionPep.campos.$each.$response.$errors[scope.$index].TipoPep.length"></div>
              <el-form-item v-for="error in validacionPep.campos.$each.$response.$errors[scope.$index].TipoPep" :key="error" class="section-error-message">
                {{ error.$message }}
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column width="50">
            <template v-slot="scope">
              <el-button v-if="scope.$index > 0"  circle class="dinamic-form-button" size="small" type="success" @click="EliminarFila(scope.$index)">
                <font-awesome-icon
                :icon="['fas', 'minus']"
                />
              </el-button>
              
                <div style="height:42px;"></div>
            </template>
          </el-table-column>

        </el-table>
      </el-col>

      <el-col :span="2">
        <el-button circle class="dinamic-form-button" size="small" type="success" @click="AgregarFila"><font-awesome-icon
                :icon="['fas', 'plus']"
              /></el-button>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>

import useVuelidate from '@vuelidate/core';
import { defineComponent,ref, onMounted  } from 'vue';
import PepValidacion from '../../validation/Pep';
import '../../assets/sass/components/DueDiligence/_pep.scss'
import { useStore } from "vuex";

export default defineComponent({
    props: {
        scopePep: String,
        parametricas: {},
        infoFormularioPep: {}
    },
    setup(props) {
        
        const store = useStore();
        let [objetoPep, reglasValidacion] = new PepValidacion().ObtenerReglasValidacion();
        
        const AgregarFila = () => {

            objetoPep.campos.push({
                Nombre: '',
                TipoId: '',
                NumeroIdentificacion: '',
                TipoVinculo: '',
                TipoPep: ''
            }); 
        }   
        const EliminarFila = (index) => {
            objetoPep.campos.splice(index, 1);
        }

        const reiniciarObjetoPEP = () => {
          objetoPep.campos = [];
          AgregarFila();
        }

        onMounted(() => {
          let i;

        if(props.infoFormularioPep != "No se encuentra diligenciamiento del formulario para el empleador consultado" && props.infoFormularioPep.campos != null)
        {      
          if((props.infoFormularioPep.campos.length > 0) && (Object.values(props.infoFormularioPep.campos[0]).some(x => x !== null && x !== '' && x !== undefined)))
          {
            objetoPep.campos.splice(0, 1);
          }

             for(i = 0; i < props.infoFormularioPep.campos.length; i++)
            {
                if(Object.values(props.infoFormularioPep.campos[i]).some(x => x !== null && x !== '' && x !== undefined))
                {
                    objetoPep.campos.push({
                        Nombre: props.infoFormularioPep.campos[i].nombre,
                        TipoId: props.infoFormularioPep.campos[i].tipoId,
                        NumeroIdentificacion: props.infoFormularioPep.campos[i].numeroIdentificacion,
                        TipoVinculo: props.infoFormularioPep.campos[i].tipoVinculo,
                        TipoPep: props.infoFormularioPep.campos[i].tipoPep
                    }); 
                }             
            }
        }
      })

        const validacionPep = useVuelidate(reglasValidacion, objetoPep, { $scope: props.scopePep })   
        return { 
          AgregarFila, EliminarFila, validacionPep, objetoPep, reiniciarObjetoPEP,
          store
        }
    },
    methods:{
        ValidarCamposFila(index)
      {
        let FilaLlena = !Object.values(this.objetoPep.campos[index]).some(x => x === null || x === '' || x === undefined)
        if(FilaLlena)
        {
          this.$emit('GenerarGuardadoParcial');
        }
      }
    }
})

</script>
