<template>
<el-form>
<el-row :gutter="24">
      <el-col :span="24">
        <h1 class="section-text">Información complementaria PEP</h1>
      </el-col>
    </el-row>

    <el-row :gutter="24">
      <el-col :span="24">
        <p class="pep-text"> Incluir la siguiente información, nombre y número de identificación: </p>
        <p class="pep-text">
          <ol>
            <li> Los cónyuges o compañeros permanentes del PEP </li>
            <li> Los familiares de las PEP, hasta el segundo grado de consanguinidad, segundo de afinidad y primero civil. </li>
            <li> Los asociados de un PEP, cuando el PEP sea socio de, o esté asociado a, una persona jurídica y, además, sea propietario directa o indirectamente de una participación superior al 5% de la persona jurídica, o ejerza el control de la persona jurídica, en los términos del artículo 261 del Código de Comercio. </li>
          </ol>
        </p>
      </el-col>
    </el-row>

   <div class="pepInfoLegal">
    <el-row :gutter="24">
      <el-col :span="22">
        <el-table height="250" :data="objetoInfoPepAccionista_Natural.PepAN" :span="24">

          <el-table-column width="40%" >
              <template v-slot="scope">
                 <span><strong>{{scope.$index + 1}}</strong></span>
                 <div v-if="HabilitaCamposPepAN" style="height:62px;"></div>
              </template>
          </el-table-column>

          <el-table-column class="section-text-color" label="Nombre del accionista*" width="220px;">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.NombreAN_PEP" :class="[validacionInfoPepAccionista_Natural.PepAN.$each.$response.$errors[scope.$index].NombreAN_PEP.length ? 'border-invalid' : 'border-valid']" :disabled="!HabilitaCamposPepAN" @change="ValidarCamposFila(scope.$index)"/>
              <div v-if="HabilitaCamposPepAN">
                <div style="height:62px;" v-show="!validacionInfoPepAccionista_Natural.PepAN.$each.$response.$errors[scope.$index].NombreAN_PEP.length"></div>
              <el-form-item v-if="validacionInfoPepAccionista_Natural.PepAN.$each.$response.$errors[scope.$index].NombreAN_PEP[0]" :key="validacionInfoPepAccionista_Natural.PepAN.$each.$response.$errors[scope.$index].NombreAN_PEP[0].$message" class="section-error-message">
                {{ validacionInfoPepAccionista_Natural.PepAN.$each.$response.$errors[scope.$index].NombreAN_PEP[0].$message }}
              </el-form-item>
              </div>
            </template>
          </el-table-column>

          <el-table-column class="section-text-color" label="Nombre de PEP*" width="220px;">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.NombrePEP" :class="[validacionInfoPepAccionista_Natural.PepAN.$each.$response.$errors[scope.$index].NombrePEP.length ? 'border-invalid' : 'border-valid']" :disabled="!HabilitaCamposPepAN" @change="ValidarCamposFila(scope.$index)"/>
              <div v-if="HabilitaCamposPepAN">
                <div style="height:62px;" v-show="!validacionInfoPepAccionista_Natural.PepAN.$each.$response.$errors[scope.$index].NombrePEP.length"></div>
              <el-form-item v-if="validacionInfoPepAccionista_Natural.PepAN.$each.$response.$errors[scope.$index].NombrePEP[0]" :key="validacionInfoPepAccionista_Natural.PepAN.$each.$response.$errors[scope.$index].NombrePEP[0].$message" class="section-error-message">
                {{ validacionInfoPepAccionista_Natural.PepAN.$each.$response.$errors[scope.$index].NombrePEP[0].$message }}
              </el-form-item>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Tipo ID*" width="140px;">
            <template v-slot="scope">
              <el-select v-model="scope.row.TipoIdPEPAN" placeholder="Seleccionar" :class="[validacionInfoPepAccionista_Natural.PepAN.$each.$response.$errors[scope.$index].TipoIdPEPAN.length ? 'border-invalid' : 'border-valid']" :disabled="!HabilitaCamposPepAN" @change="ValidarCamposFila(scope.$index)">
               <el-option 
                 v-for="item in parametricas.tipoDeIdentificacion && parametricas.tipoDeIdentificacion
                 .filter(t => t.valor !== 'NIT' && t.valor !== 'TI')" 
                 :key="item.codigo" 
                 :label="item.valor" 
                 :value="item.valor" />
              </el-select>
              <div v-if="HabilitaCamposPepAN">
                <div style="height:62px;" v-show="!validacionInfoPepAccionista_Natural.PepAN.$each.$response.$errors[scope.$index].TipoIdPEPAN.length"></div>
              <el-form-item v-for="error in validacionInfoPepAccionista_Natural.PepAN.$each.$response.$errors[scope.$index].TipoIdPEPAN" :key="error" class="section-error-message">
                {{ error.$message }}
              </el-form-item>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="N° ID*" width="180px;">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.NumeroIdentificacionPEPAN" :class="[validacionInfoPepAccionista_Natural.PepAN.$each.$response.$errors[scope.$index].NumeroIdentificacionPEPAN.length ? 'border-invalid' : 'border-valid']" :disabled="!HabilitaCamposPepAN" @change="ValidarCamposFila(scope.$index)"/>
              <div v-if="HabilitaCamposPepAN">
                <div style="height:62px;" v-show="!validacionInfoPepAccionista_Natural.PepAN.$each.$response.$errors[scope.$index].NumeroIdentificacionPEPAN.length"></div>
              <el-form-item v-if="validacionInfoPepAccionista_Natural.PepAN.$each.$response.$errors[scope.$index].NumeroIdentificacionPEPAN[0]" :key="validacionInfoPepAccionista_Natural.PepAN.$each.$response.$errors[scope.$index].NumeroIdentificacionPEPAN[0].$message" class="section-error-message">
                {{ validacionInfoPepAccionista_Natural.PepAN.$each.$response.$errors[scope.$index].NumeroIdentificacionPEPAN[0].$message }}
              </el-form-item>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Tipo de vínculo*" width="180px;">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.TipoVinculoPEPAN" :class="[validacionInfoPepAccionista_Natural.PepAN.$each.$response.$errors[scope.$index].TipoVinculoPEPAN.length ? 'border-invalid' : 'border-valid']" :disabled="!HabilitaCamposPepAN" @change="ValidarCamposFila(scope.$index)"/>
              <div v-if="HabilitaCamposPepAN">
                <div style="height:62px;" v-show="!validacionInfoPepAccionista_Natural.PepAN.$each.$response.$errors[scope.$index].TipoVinculoPEPAN.length"></div>
                <el-form-item v-if="validacionInfoPepAccionista_Natural.PepAN.$each.$response.$errors[scope.$index].TipoVinculoPEPAN[0]" :key="validacionInfoPepAccionista_Natural.PepAN.$each.$response.$errors[scope.$index].TipoVinculoPEPAN[0].$message" class="section-error-message">
                {{ validacionInfoPepAccionista_Natural.PepAN.$each.$response.$errors[scope.$index].TipoVinculoPEPAN[0].$message }}
              </el-form-item>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Tipo de PEP*" width="135px;">
            <template v-slot="scope">
              <el-select v-model="scope.row.TipoPEPAN" placeholder="Seleccionar" :class="[validacionInfoPepAccionista_Natural.PepAN.$each.$response.$errors[scope.$index].TipoPEPAN.length ? 'border-invalid' : 'border-valid']" :disabled="!HabilitaCamposPepAN" @change="ValidarCamposFila(scope.$index)">
                <el-option 
                 v-for="item in parametricas.tipoDePep" 
                 :key="item.codigo" 
                 :label="item.valor" 
                 :value="item.valor" />
              </el-select>
              <div v-if="HabilitaCamposPepAN">
                <div style="height:62px;" v-show="!validacionInfoPepAccionista_Natural.PepAN.$each.$response.$errors[scope.$index].TipoPEPAN.length"></div>
              <el-form-item v-for="error in validacionInfoPepAccionista_Natural.PepAN.$each.$response.$errors[scope.$index].TipoPEPAN" :key="error" class="section-error-message">
                {{ error.$message }}
              </el-form-item>
              </div>
            </template>
          </el-table-column>

          <el-table-column width="50">
            <template v-slot="scope">
              <el-button v-if="scope.$index > 0"  circle class="dinamic-form-button" size="small" type="success" @click="EliminarFilaPEPAN(scope.$index)">
                <font-awesome-icon
                :icon="['fas', 'minus']"
                />
              </el-button>
                <div style="height:62px;"></div>
            </template>
          </el-table-column>

        </el-table>
      </el-col>

      <el-col :span="2">
        <el-button circle class="dinamic-form-button" size="small" type="success" @click="AgregarFilaPEPAN" :disabled="!HabilitaCamposPepAN">
          <font-awesome-icon
                :icon="['fas', 'plus']"
                />
        </el-button>
      </el-col>
    </el-row>
   </div>
    </el-form>
</template>

<script>

import useVuelidate from '@vuelidate/core';
import { defineComponent, onMounted } from 'vue';
import InfoPepAccionista_NaturalValidacion from '../../validation/InfoPepAccionista_Natural';
import '../../assets/sass/components/DueDiligence/_legal.scss';

export default defineComponent({
    props: {
        scopeInfoPepAccionista_Natural: String,
        HabilitaCamposPepAN: Boolean,
        parametricas: {},
        infoFormularioPepAccNatural: {}
    },
    setup(props) {
        
        let [objetoInfoPepAccionista_Natural, reglasValidacion] = new InfoPepAccionista_NaturalValidacion().ObtenerReglasValidacion();

        const AgregarFilaPEPAN = () => {

            objetoInfoPepAccionista_Natural.PepAN.push({
                NombreAN_PEP: '',
                NombrePEP: '',
                TipoIdPEPAN: '',
                NumeroIdentificacionPEPAN: '',
                TipoVinculoPEPAN: '',
                TipoPEPAN: ''
            }); 
        }

        const EliminarFilaPEPAN = (index) => {
            objetoInfoPepAccionista_Natural.PepAN.splice(index, 1);
        }

        const reiniciarObjetoInfoPepAccionista_Natural = () => {
          objetoInfoPepAccionista_Natural.PepAN = [];
          AgregarFilaPEPAN();
        }
        const validacionInfoPepAccionista_Natural = useVuelidate(reglasValidacion, objetoInfoPepAccionista_Natural, { $scope: props.scopeInfoPepAccionista_Natural })

        const tiposIdentificacion = [{
            value: "2",
            label: "CC",
          },
          {
            value: "3",
            label: "CE",
          },
          {
            value: "5",
            label: "PAS",
          },
          {
            value: "6",
            label: "PE",
          }
        ]

        onMounted(() => {
          let i;
          
        if(props.infoFormularioPepAccNatural != "No se encuentra diligenciamiento del formulario para el empleador consultado" && props.infoFormularioPepAccNatural.pepAN != null)
        {      
          if((props.infoFormularioPepAccNatural.pepAN.length > 0) && (Object.values(props.infoFormularioPepAccNatural.pepAN[0]).some(x => x !== null && x !== '' && x !== undefined)))
          {
            objetoInfoPepAccionista_Natural.PepAN.splice(0, 1);
          }

             for(i = 0; i < props.infoFormularioPepAccNatural.pepAN.length; i++)
            {
                if(Object.values(props.infoFormularioPepAccNatural.pepAN[i]).some(x => x !== null && x !== '' && x !== undefined))
                {
                    objetoInfoPepAccionista_Natural.PepAN.push({
                        NombreAN_PEP: props.infoFormularioPepAccNatural.pepAN[i].nombreAN_PEP,
                        NombrePEP: props.infoFormularioPepAccNatural.pepAN[i].nombrePEP,
                        TipoIdPEPAN: props.infoFormularioPepAccNatural.pepAN[i].tipoIdPEPAN,
                        NumeroIdentificacionPEPAN: props.infoFormularioPepAccNatural.pepAN[i].numeroIdentificacionPEPAN,
                        TipoVinculoPEPAN: props.infoFormularioPepAccNatural.pepAN[i].tipoVinculoPEPAN,
                        TipoPEPAN: props.infoFormularioPepAccNatural.pepAN[i].tipoPEPAN
                    }); 
                }             
            }
        }
      })


        return { 
          AgregarFilaPEPAN, 
          EliminarFilaPEPAN, 
          validacionInfoPepAccionista_Natural, objetoInfoPepAccionista_Natural, reiniciarObjetoInfoPepAccionista_Natural,
          tiposIdentificacion
        }
    },
    methods:{
        ValidarCamposFila(index)
        {
          let FilaLlena = !Object.values(this.objetoInfoPepAccionista_Natural.PepAN[index]).some(x => x === null || x === '' || x === undefined)
          if(FilaLlena)
          {
            this.$emit('GenerarGuardadoParcial');
          }
        }
    }
})

</script>
