<template>
<el-form>
    <el-row :gutter="24">
      <el-col :span="24">
        <h1 class="section-text">Información representación legal</h1>
      </el-col>
    </el-row>

    <el-row :gutter="24">
      <el-col :span="24">
        <h1 class="section-text">Representantes legales principales</h1>
      </el-col>
    </el-row>
    <div class="repTitular">
    <el-row :gutter="24">
      <el-col :span="22">
        <el-table height="270" :data="objetoInfoRepresentanteTitular.RLTitular" :span="24" header-align="left">

          <el-table-column width="20%" >
              <template v-slot="scope">
                 <span><strong>{{scope.$index + 1}}</strong></span>
                 <div style="height:62px;"></div>
              </template>
          </el-table-column>

          <el-table-column class="section-text-color" label="Nombre de representantes legales*" width="235px;">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.NombreRLTitular" :class="[validacionInfoRepresentanteTitular.RLTitular.$each.$response.$errors[scope.$index].NombreRLTitular.length ? 'border-invalid' : 'border-valid']" @change="ValidarCamposFila(scope.$index)" />
                <div style="height:62px;" v-show="!validacionInfoRepresentanteTitular.RLTitular.$each.$response.$errors[scope.$index].NombreRLTitular.length"></div>
                  <el-form-item v-if="validacionInfoRepresentanteTitular.RLTitular.$each.$response.$errors[scope.$index].NombreRLTitular[0]" :key="validacionInfoRepresentanteTitular.RLTitular.$each.$response.$errors[scope.$index].NombreRLTitular[0].$message" class="section-error-message">
                    {{ validacionInfoRepresentanteTitular.RLTitular.$each.$response.$errors[scope.$index].NombreRLTitular[0].$message }}
              </el-form-item>
            </template>
          </el-table-column>         

          <el-table-column label="Tipo ID*" width="140px;">
            <template v-slot="scope">
              <el-select v-model="scope.row.TipoIdRLTitular" placeholder="Seleccionar" :class="[validacionInfoRepresentanteTitular.RLTitular.$each.$response.$errors[scope.$index].TipoIdRLTitular.length ? 'border-invalid' : 'border-valid']" @change="ValidarCamposFila(scope.$index)">
                <el-option v-for="item in tiposIdentificacion" :key="item.codigo" :label="item.valor" :value="item.valor" />
              </el-select>
              <div style="height:62px;" v-show="!validacionInfoRepresentanteTitular.RLTitular.$each.$response.$errors[scope.$index].TipoIdRLTitular.length"></div>
              <el-form-item v-for="error in validacionInfoRepresentanteTitular.RLTitular.$each.$response.$errors[scope.$index].TipoIdRLTitular" :key="error" class="section-error-message">
                {{ error.$message }}
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column label="N° ID*" width="180px;">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.NumeroIdentificacionRLTitular" :class="[validacionInfoRepresentanteTitular.RLTitular.$each.$response.$errors[scope.$index].NumeroIdentificacionRLTitular.length ? 'border-invalid' : 'border-valid']" @change="ValidarCamposFila(scope.$index)" />
              <div style="height:62px;" v-show="!validacionInfoRepresentanteTitular.RLTitular.$each.$response.$errors[scope.$index].NumeroIdentificacionRLTitular.length"></div>
              <el-form-item v-if="validacionInfoRepresentanteTitular.RLTitular.$each.$response.$errors[scope.$index].NumeroIdentificacionRLTitular[0]" :key="validacionInfoRepresentanteTitular.RLTitular.$each.$response.$errors[scope.$index].NumeroIdentificacionRLTitular[0].$message" class="section-error-message">
                {{ validacionInfoRepresentanteTitular.RLTitular.$each.$response.$errors[scope.$index].NumeroIdentificacionRLTitular[0].$message }}
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column class-name="header_color" width="140px;">
            <template #header>
            <span>¿Es PEP?* </span>
            <el-tooltip
              content="PEP es la Persona Expuesta Políticamente."
              placement="top"
              open-delay="0"
              effect="customized"
            >
              <font-awesome-icon :icon="['fas', 'info-circle']" color="#00805d" style="font-size: 13px;" />
            </el-tooltip>
            </template>
            <template v-slot="scope">
              <el-select v-model="scope.row.EsPEPRLTitular" placeholder="Seleccionar" :class="[validacionInfoRepresentanteTitular.RLTitular.$each.$response.$errors[scope.$index].EsPEPRLTitular.length ? 'border-invalid' : 'border-valid']" @change="ValidaSiPEP();ValidarCamposFila(scope.$index)">
                <el-option v-for="item in parametricas.generico" :key="item.codigo" :label="item.valor" :value="item.valor" />
              </el-select>
              <div style="height:62px;" v-show="!validacionInfoRepresentanteTitular.RLTitular.$each.$response.$errors[scope.$index].EsPEPRLTitular.length"></div>
              <el-form-item v-for="error in validacionInfoRepresentanteTitular.RLTitular.$each.$response.$errors[scope.$index].EsPEPRLTitular" :key="error" class="section-error-message">
                {{ error.$message }}
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column label="¿Tiene vínculos familiares con PEP?*" width="180px;" class-name="header_color">
            <template #header>
            <span>¿Tiene vínculos familiares con PEP?* </span>
            <el-tooltip
              placement="top"
              open-delay="0"  
              content="Las personas que tengan una sociedad conyugal, de hecho, o derecho con las personas expuestas políticamente nacionales o extranjeras, así como a sus 
              familiares hasta el segundo grado de consanguinidad, segundo de afinidad y primero civil." 
              effect="customized"           
            >        
              <font-awesome-icon :icon="['fas', 'info-circle']" color="#00805d" style="font-size: 13px;" />
            </el-tooltip>
            </template>
            <template v-slot="scope">
              <el-select v-model="scope.row.VinculosFamPEP_RLTitular" placeholder="Seleccionar" :class="[validacionInfoRepresentanteTitular.RLTitular.$each.$response.$errors[scope.$index].VinculosFamPEP_RLTitular.length ? 'border-invalid' : 'border-valid']" @change="ValidaSiPEP();ValidarCamposFila(scope.$index)">
                <el-option v-for="item in parametricas.generico" :key="item.codigo" :label="item.valor" :value="item.valor" />
              </el-select>
              <div style="height:62px;" v-show="!validacionInfoRepresentanteTitular.RLTitular.$each.$response.$errors[scope.$index].VinculosFamPEP_RLTitular.length"></div>
              <el-form-item v-for="error in validacionInfoRepresentanteTitular.RLTitular.$each.$response.$errors[scope.$index].VinculosFamPEP_RLTitular" :key="error" class="section-error-message">
                {{ error.$message }}
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column width="50">
            <template v-slot="scope">
              <el-button v-if="scope.$index > 0"  circle class="dinamic-form-button" size="small" type="success" @click="EliminarFilaRLTitular(scope.$index);ValidaSiPEP();">
                <font-awesome-icon
                :icon="['fas', 'minus']"
                />
              </el-button>
                <div style="height:62px;"></div>
            </template>
          </el-table-column>

        </el-table>
      </el-col>

      <el-col :span="2">
        <el-button circle class="dinamic-form-button" size="small" type="success" @click="AgregarFilaRLTitular">
          <font-awesome-icon
                :icon="['fas', 'plus']"
                />
        </el-button>
      </el-col>
    </el-row>
    </div>
</el-form>
</template>

<script>

import useVuelidate from '@vuelidate/core';
import { defineComponent, onUpdated, onMounted } from 'vue';
import InfoRepresentanteTitularValidacion from '../../validation/InfoRepresentanteTitular';
import '../../assets/sass/components/DueDiligence/_legal.scss';

export default defineComponent({
props: {
        scopeRepresentanteTitular: String,
        parametricas: {},
        infoFormularioRLTitular: {}
    },
    setup(props) {

        let [objetoInfoRepresentanteTitular, reglasValidacion] = new InfoRepresentanteTitularValidacion().ObtenerReglasValidacion();

        const AgregarFilaRLTitular = () => {

            objetoInfoRepresentanteTitular.RLTitular.push({
                NombreRLTitular: '',
                TipoIdRLTitular: '',
                NumeroIdentificacionRLTitular: '',
                EsPEPRLTitular: '',
                VinculosFamPEP_RLTitular: ''

            }); 
        }

        const EliminarFilaRLTitular = (index) => {
            objetoInfoRepresentanteTitular.RLTitular.splice(index, 1);
        }

        const validacionInfoRepresentanteTitular = useVuelidate(reglasValidacion, objetoInfoRepresentanteTitular, { $scope: props.scopeRepresentanteTitular })

        const tiposIdentificacion = props.parametricas.tipoDeIdentificacion.filter(tipo => tipo.codigo !== '0007');

        onUpdated(() => {
            window.scrollTo(0, 0);
        })

        onMounted(() => {
          let i;
          
        if(props.infoFormularioRLTitular != "No se encuentra guardado parcial para el empleador consultado" && props.infoFormularioRLTitular.rlTitular != null)
        {      
          if((props.infoFormularioRLTitular.rlTitular.length > 0) && (Object.values(props.infoFormularioRLTitular.rlTitular[0]).some(x => x !== null && x !== '' && x !== undefined)))
          {
            objetoInfoRepresentanteTitular.RLTitular.splice(0, 1);
          }

             for(i = 0; i < props.infoFormularioRLTitular.rlTitular.length; i++)
            {
                 if(Object.values(props.infoFormularioRLTitular.rlTitular[i]).some(x => x !== null && x !== '' && x !== undefined))
                 {
                    objetoInfoRepresentanteTitular.RLTitular.push({
                        NombreRLTitular: props.infoFormularioRLTitular.rlTitular[i].nombreRLTitular,
                        TipoIdRLTitular: props.infoFormularioRLTitular.rlTitular[i].tipoIdRLTitular,
                        NumeroIdentificacionRLTitular: props.infoFormularioRLTitular.rlTitular[i].numeroIdentificacionRLTitular,
                        EsPEPRLTitular: props.infoFormularioRLTitular.rlTitular[i].esPEPRLTitular,
                        VinculosFamPEP_RLTitular: props.infoFormularioRLTitular.rlTitular[i].vinculosFamPEP_RLTitular
                    }); 
                 }             
            }
        }
      })

        return { 
          AgregarFilaRLTitular,  
          EliminarFilaRLTitular, 
          validacionInfoRepresentanteTitular, objetoInfoRepresentanteTitular,
          tiposIdentificacion
        }
    },
    methods:{
        ValidaSiPEP()
        {
            let i;
            let Result = false;
            for(i = 0; i < this.objetoInfoRepresentanteTitular.RLTitular.length; i++)
            {
                if(this.objetoInfoRepresentanteTitular.RLTitular[i].EsPEPRLTitular.includes("Si") || this.objetoInfoRepresentanteTitular.RLTitular[i].VinculosFamPEP_RLTitular.includes("Si"))
                {
                    Result = true;
                    break;
                }
                
            }
            this.$emit('MostrarSeccionPepRT',Result)

        },
        ValidarCamposFila(index)
        {
          let FilaLlena = !Object.values(this.objetoInfoRepresentanteTitular.RLTitular[index]).some(x => x === null || x === '' || x === undefined)
          if(FilaLlena)
          {
            this.$emit('GenerarGuardadoParcial');
          }
        },
      }
  })

</script>