import { reactive } from 'vue'
import { required, helpers, minLength, maxLength } from '@vuelidate/validators'
import GeneralRules from './GeneralRules';

class InfoPepAccionista_NaturalValidacion{

     public ObtenerReglasValidacion() {

         const objetoInfoPepAccionista_Natural = reactive({
             PepAN:[{
                NombreAN_PEP: '',
                NombrePEP: '',
                TipoIdPEPAN: '',
                NumeroIdentificacionPEPAN: '',
                TipoVinculoPEPAN: '',
                TipoPEPAN: ''
             }]
         });

         const requiredMessage = 'Campo obligatorio';
         
         const patternRule = (pattern: RegExp) => ({
            $validator: (value: string) => pattern.test(value),
            trigger: ['blur', 'change'],
            });

        const validarNumeroPorTipoId = helpers.withMessage
            ('No se admiten espacios ni caracteres especiales', 
                (value: string, {TipoIdPEPAN}:{TipoIdPEPAN:string}) => {
                    if(!value || !TipoIdPEPAN) return false;
                        return GeneralRules.ValidateRegex(value,TipoIdPEPAN);      
            });

        const validarTamanoNumero = helpers.withMessage
            ('El rango de caracteres no es admitido', 
                (value: string, {TipoIdPEPAN}:{TipoIdPEPAN:string}) => {
                    if(!value || !TipoIdPEPAN) return false;
                        return GeneralRules.ValidateSize(value,TipoIdPEPAN);      
            });
   
        const reglaCaracteresEspeciales = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ]+( [a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$/;
        
         const reglasValidacion = {
            PepAN: {
                 $each: helpers.forEach({
                    NombreAN_PEP: {
                         required: helpers.withMessage(requiredMessage, required),
                         minLength: helpers.withMessage('El rango de caracteres admitidos en este campo es de 2 a 100',minLength(2)),  
                         maxLength: helpers.withMessage('El rango de caracteres admitidos en este campo es de 2 a 100',maxLength(100)),         
                         patternRuleCarecteres: helpers.withMessage('No se admiten espacios al inicio o al final ni caracteres especiales', patternRule(reglaCaracteresEspeciales))
                     },
                     NombrePEP: {
                         required: helpers.withMessage(requiredMessage, required),
                         minLength: helpers.withMessage('El rango de caracteres admitidos en este campo es de 2 a 100',minLength(2)),  
                         maxLength: helpers.withMessage('El rango de caracteres admitidos en este campo es de 2 a 100',maxLength(100)),         
                         patternRuleCarecteres: helpers.withMessage('No se admiten espacios al inicio o al final ni caracteres especiales', patternRule(reglaCaracteresEspeciales))
                     },
                     TipoIdPEPAN: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     NumeroIdentificacionPEPAN: {
                         required: helpers.withMessage(requiredMessage, required),
                         validarNumeroPorTipoId,
                         validarTamanoNumero
                     },
                     TipoVinculoPEPAN: {
                        required: helpers.withMessage(requiredMessage, required),
                        maxLength: helpers.withMessage('El rango de caracteres en este campo es de 1 a 10',maxLength(10)),
                        patternRuleCarecteres: helpers.withMessage('No se admiten espacios ni caracteres especiales', patternRule(reglaCaracteresEspeciales))
                    },
                    TipoPEPAN: {
                        required: helpers.withMessage(requiredMessage, required),
                    },
                 })
             }
         };

        return [objetoInfoPepAccionista_Natural, reglasValidacion] as const;
    }
}

export default InfoPepAccionista_NaturalValidacion;