<template>
    <el-form-item class="field" label="País">
      <template #label v-if="props.requiredAsterisk">
        <span v-html="asterisk('País')" />
      </template>
      <el-select
        v-model="divipolas.Pais"
        placeholder="Seleccione"
        class="pv-input-size"
        @change="PaisSeleccionado"
        @input="v$.Pais.$touch()"
        :class="{ 'p-invalid': v$.Pais.$error }"
      >     
      <el-option
              v-for="pais in paises"
              :key="pais.PaisId"
              :label="pais.nombre"
              :value="pais.nombre"
            />      
          </el-select> 
      <div>
        <div
          v-for="error of v$.Pais.$errors"
          :key="error.$uid"
          class="el-form-item__error"
          :style="{ color: '#EE7417', fontSize: '14px' }"
          style="top: 55%"
        >
          {{ error.$message }}
        </div>
        {{ props.footerPais }}
      </div>
    </el-form-item>
    <el-form-item class="field" label="Departamento">
      <template #label v-if="props.requiredAsterisk">
        <span v-html="asterisk('Departamento')" />
      </template>
      <el-select
        v-model="divipolas.Departamento"
        class="pv-input-size"
        placeholder="Seleccione"
        @change="DptoSeleccionado(true)"
        @input="v$.Departamento.$touch()"
        :class="{ 'p-invalid': v$.Departamento.$error }"
        :disabled="divipolas.Pais != 'COLOMBIA'"
      >
      <el-option
              v-for="departamento in departamentos"
              :key="departamento.nombre"
              :label="departamento.nombre"
              :value="departamento.nombre"
            />     
        </el-select>  
      <div>
        <div
          v-for="error of v$.Departamento.$errors"
          :key="error.$uid"
          class="el-form-item__error"
          :style="{ color: '#EE7417', fontSize: '14px' }"
        >
          {{ error.$message }}
        </div>
      </div>
    </el-form-item>
    <el-form-item class="field" label="Ciudad">
      <template #label v-if="props.requiredAsterisk">
        <span v-html="asterisk('Ciudad')" />
      </template>
      <el-select 
        v-model="divipolas.Municipio"
        placeholder="Seleccione"
        class="pv-input-size"
        :disabled="divipolas.Pais != 'COLOMBIA'"
        @change="MunicipioSeleccionado()"
        :class="{ 'p-invalid': v$.Municipio.$error }"
      >
      <el-option
              v-for="municipio in municipiosPorDpto"
              :key="municipio.nombre"
              :label="municipio.nombre"
              :value="municipio.nombre"
            />      
          </el-select> 
      <div>
        <div
          v-for="error of v$.Municipio.$errors"
          :key="error.$uid"
          class="el-form-item__error"
          :style="{ color: '#EE7417', fontSize: '14px' }"
        >
          {{ error.$message }}
        </div>
      </div>
    </el-form-item>
  </template>
   
  <script>
  import { useStore } from "vuex";
  import { Split, CampoNuloOVacio } from "@/components/helpers/helpers";
  import useVuelidate from "@vuelidate/core";
  import { computed, onMounted, ref, watch } from "vue";
  import DivipolaValidacion from "@/validation/DivipolaDian";
  export default {
    props: {
      paisDefecto: String,
      dptoDefecto: String,
      municipioDefecto: String,
      divipola: String,
      footerPais: String,
      requiredAsterisk: Boolean,
      divipolaModel: {
        type: Object,
        required: false,
        default: () => ({
          Pais: "",
          Departamento: "",
          Municipio: "",
        }),
      },
    },
    setup(props) {
      const store = useStore();
      const paises = ref([{}]);
      const codigoPais = ref("");
      const codigoDpto = ref("");
      const codigoMunicipio = ref("");
      const departamentos = ref();
      const municipios = ref();
      const ObjDivipolas = store.state.ddModule.divipolas;
      const objParametricaPais = store.state.ddModule.parametricas.pais;
    
      let [divipolas, reglasDivipolas] =
        new DivipolaValidacion().ObtenerReglasValidacion();
  
      const v$ = useVuelidate(reglasDivipolas, divipolas, {
        $scope: props.divipola,
      });
  
      watch(
        () => props.divipolaModel,
        (newVal) => {
          Object.assign(divipolas, newVal);
        },
        { immediate: true }
      );
  
      const asterisk = (name) => {
        return "<span class='required-asterisk'>*</span>" + name;
      };
  
      const PaisSeleccionado = () => {
        v$.value.Pais.$touch();
        if (divipolas.Pais) {
            codigoPais.value = "45";
        } else {
          codigoPais.value = "";
          codigoDpto.value = "";
          codigoMunicipio.value = "";
          divipolas.Pais = "";
          divipolas.Departamento = "";
          divipolas.Municipio = "";
        }
        
      };
      const DptoSeleccionado = (eliminarMunicipio) => {
         v$.value.Departamento.$touch();
        if (eliminarMunicipio) {
          divipolas.Municipio = "";
          codigoMunicipio.value = "";
        }
        if (divipolas.Departamento) {
          codigoDpto.value = Split(divipolas.Departamento, 0, "-");
        } else {
          divipolas.Departamento = "";
          codigoDpto.value = "";
        }
      };
      const MunicipioSeleccionado = () => {
         v$.value.Municipio.$touch();
        if (divipolas.Municipio) {
          codigoMunicipio.value = Split(divipolas.Municipio, 0, "-");
        } else {
          codigoMunicipio.value = "";
          divipolas.Municipio = "";
        }
      };
      const municipiosPorDpto = computed(() => {
        if (codigoDpto.value) {
          return municipios.value
            .filter((municipio) => {
              return municipio.codigoDepartamento == codigoDpto.value;
            })
            .sort((a, b) =>
              a.nombreMunicipio.localeCompare(b.nombreMunicipio)
            );
        }
      });
      
      function ObtenerDivipolas() {       
        departamentos.value = ObjDivipolas.departamentos.sort((a, b) => 
        {
        const nombreA = a.nombre.replace(/^\d+-/, '').trim();
        const nombreB = b.nombre.replace(/^\d+-/, '').trim();  
        return nombreA.localeCompare(nombreB);
        });
        municipios.value = ObjDivipolas.municipios;     
        paises.value = objParametricaPais;
        PaisSeleccionado();
        DptoSeleccionado(true);
        MunicipioSeleccionado();
      }
      onMounted(() => {
        ObtenerDivipolas();
        
      });
      return {
        v$,
        paises,
        departamentos,
        municipios,
        municipiosPorDpto,
        divipolas,
        codigoPais,
        codigoDpto,
        codigoMunicipio,
        CampoNuloOVacio,
        PaisSeleccionado,
        DptoSeleccionado,
        MunicipioSeleccionado,
        props,
        asterisk,
      };
    },
  };
  </script>