<template>
  <el-form>
    <el-row :gutter="24">
      <el-col :span="24">
        <h1 class="section-text">Información accionistas naturales</h1>
      </el-col>
    </el-row>

    <el-row :gutter="24">
      <el-col :span="24">
        <p class="pep-text">
          *Se debe relacionar la información de todos los accionistas con una participación mayor al 5%.
        </p>
      </el-col>
    </el-row>

    <div class="natural">
    <el-row :gutter="24">
      <el-col :span="22">
        <el-table height="300" :data="objetoInfoAccionista_Natural.An" :span="24">

          <el-table-column width="20%" >
              <template v-slot="scope">
                 <span><strong>{{scope.$index + 1}}</strong></span>
                 <div style="height:62px;"></div>
              </template>
          </el-table-column>

          <el-table-column class="section-text-color" label="Nombre del accionista*" width="220px;">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.NombreAN" :class="[validacionInfoAccionista_Natural.An.$each.$response.$errors[scope.$index].NombreAN.length ? 'border-invalid' : 'border-valid']" @change="ValidarCamposFila(scope.$index)" />
              <div style="height:62px;" v-show="!validacionInfoAccionista_Natural.An.$each.$response.$errors[scope.$index].NombreAN.length"></div>
              <el-form-item v-if="validacionInfoAccionista_Natural.An.$each.$response.$errors[scope.$index].NombreAN[0]" :key="validacionInfoAccionista_Natural.An.$each.$response.$errors[scope.$index].NombreAN[0].$message" class="section-error-message">
                {{ validacionInfoAccionista_Natural.An.$each.$response.$errors[scope.$index].NombreAN[0].$message }}
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column label="Tipo ID*" width="140px;">
            <template v-slot="scope">
              <el-select v-model="scope.row.TipoIdAN" placeholder="Seleccionar" :class="[validacionInfoAccionista_Natural.An.$each.$response.$errors[scope.$index].TipoIdAN.length ? 'border-invalid' : 'border-valid']" @change="ValidarCamposFila(scope.$index)">
                <el-option v-for="item in parametricas.tipoDeIdentificacion" :key="item.codigo" :label="item.valor" :value="item.valor" />
              </el-select>
              <div style="height:62px;" v-show="!validacionInfoAccionista_Natural.An.$each.$response.$errors[scope.$index].TipoIdAN.length"></div>
              <el-form-item v-for="error in validacionInfoAccionista_Natural.An.$each.$response.$errors[scope.$index].TipoIdAN" :key="error" class="section-error-message">
                {{ error.$message }}
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column label="N° ID*" width="180px;">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.NumeroIdentificacionAN" :class="[validacionInfoAccionista_Natural.An.$each.$response.$errors[scope.$index].NumeroIdentificacionAN.length ? 'border-invalid' : 'border-valid']" @change="ValidarCamposFila(scope.$index)"/>
              <div style="height:62px;" v-show="!validacionInfoAccionista_Natural.An.$each.$response.$errors[scope.$index].NumeroIdentificacionAN.length"></div>
              <el-form-item v-if="validacionInfoAccionista_Natural.An.$each.$response.$errors[scope.$index].NumeroIdentificacionAN[0]" :key="validacionInfoAccionista_Natural.An.$each.$response.$errors[scope.$index].NumeroIdentificacionAN[0].$message" class="section-error-message">
                {{ validacionInfoAccionista_Natural.An.$each.$response.$errors[scope.$index].NumeroIdentificacionAN[0].$message }}
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column label="% Participación Capital o Voto*" width="170px;">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.ParticipacionCapitalAN" :class="[validacionInfoAccionista_Natural.An.$each.$response.$errors[scope.$index].ParticipacionCapitalAN.length ? 'border-invalid' : 'border-valid']" @change="CharPercentage($event,scope.$index);ValidarCamposFila(scope.$index)" @keypress="func.OnlyNumbers"/>
              <div style="height:62px;" v-show="!validacionInfoAccionista_Natural.An.$each.$response.$errors[scope.$index].ParticipacionCapitalAN.length"></div>
              <el-form-item v-if="validacionInfoAccionista_Natural.An.$each.$response.$errors[scope.$index].ParticipacionCapitalAN[0]" :key="validacionInfoAccionista_Natural.An.$each.$response.$errors[scope.$index].ParticipacionCapitalAN[0].$message" class="section-error-message">
                {{ validacionInfoAccionista_Natural.An.$each.$response.$errors[scope.$index].ParticipacionCapitalAN[0].$message }}
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column width="135px;">
            <template #header>
            <span>¿Es PEP?* </span>
            <el-tooltip
              content="PEP es la Persona Expuesta Políticamente."
              placement="top"
              open-delay="0"
              effect="customized"
            >
              <font-awesome-icon :icon="['fas', 'info-circle']" color="#00805d" style="font-size: 13px;" />
            </el-tooltip>
            </template>
            <template v-slot="scope">
              <el-select v-model="scope.row.EsPEPAN" placeholder="Seleccionar" :class="[validacionInfoAccionista_Natural.An.$each.$response.$errors[scope.$index].EsPEPAN.length ? 'border-invalid' : 'border-valid']" @change="ValidaSiPEPAN();ValidarCamposFila(scope.$index)">
                <el-option v-for="item in parametricas.generico" :key="item.codigo" :label="item.valor" :value="item.valor" />
              </el-select>
              <div style="height:62px;" v-show="!validacionInfoAccionista_Natural.An.$each.$response.$errors[scope.$index].EsPEPAN.length"></div>
              <el-form-item v-for="error in validacionInfoAccionista_Natural.An.$each.$response.$errors[scope.$index].EsPEPAN" :key="error" class="section-error-message">
                {{ error.$message }}
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column width="175px;">
            <template #header>
            <span>¿Tiene vínculos familiares con PEP?* </span>
            <el-tooltip
              placement="top"
              open-delay="0"  
              content="Las personas que tengan una sociedad conyugal, de hecho, o derecho con las personas expuestas políticamente nacionales o extranjeras, así como a sus 
              familiares hasta el segundo grado de consanguinidad, segundo de afinidad y primero civil." 
              effect="customized"           
            >        
              <font-awesome-icon :icon="['fas', 'info-circle']" color="#00805d" style="font-size: 13px;" />
            </el-tooltip>
            </template>
            <template v-slot="scope">
              <el-select v-model="scope.row.VinculosFamPEP_AN" placeholder="Seleccionar" :class="[validacionInfoAccionista_Natural.An.$each.$response.$errors[scope.$index].VinculosFamPEP_AN.length ? 'border-invalid' : 'border-valid']" @change="ValidaSiPEPAN();ValidarCamposFila(scope.$index)">
                <el-option v-for="item in parametricas.generico" :key="item.codigo" :label="item.valor" :value="item.valor" />
              </el-select>
              <div style="height:62px;" v-show="!validacionInfoAccionista_Natural.An.$each.$response.$errors[scope.$index].VinculosFamPEP_AN.length"></div>
              <el-form-item v-for="error in validacionInfoAccionista_Natural.An.$each.$response.$errors[scope.$index].VinculosFamPEP_AN" :key="error" class="section-error-message">
                {{ error.$message }}
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column width="50">
            <template v-slot="scope">
              <el-button v-if="scope.$index > 0"  circle class="dinamic-form-button" size="small" type="success" @click="EliminarFilaAN(scope.$index);ValidaSiPEPAN();">
                <font-awesome-icon
                :icon="['fas', 'minus']"
                />
              </el-button>
                <div style="height:62px;"></div>
            </template>
          </el-table-column>

        </el-table>
      </el-col>

      <el-col :span="2">
        <el-button circle class="dinamic-form-button" size="small" type="success" @click="AgregarFilaAN">
          <font-awesome-icon
                :icon="['fas', 'plus']"
                />
        </el-button>
      </el-col>
    </el-row>
    </div>
    </el-form>
</template>

<script>

import useVuelidate from '@vuelidate/core';
import { defineComponent, onUpdated, onMounted } from 'vue';
import InfoAccionista_NaturalValidacion from '../../validation/InfoAccionista_Natural';
import '../../assets/sass/components/DueDiligence/_legal.scss';
import func from '@/validation/GeneralRules';

export default defineComponent({
    props: {
        scopeInfoAccionista_Natural: String,
        parametricas: {},
        infoFormularioAccNatural: {}
    },
    setup(props) {
        
        let [objetoInfoAccionista_Natural, reglasValidacion] = new InfoAccionista_NaturalValidacion().ObtenerReglasValidacion();

        const AgregarFilaAN = () => {

            objetoInfoAccionista_Natural.An.push({
                NombreAN: '',
                TipoIdAN: '',
                NumeroIdentificacionAN: '',
                ParticipacionCapitalAN: '',
                EsPEPAN: '',
                VinculosFamPEP_AN: ''
            }); 
        }

        const EliminarFilaAN = (index) => {
            objetoInfoAccionista_Natural.An.splice(index, 1);
        }

        const reiniciarObjetoInfoAccionista_Natural = () => {
          objetoInfoAccionista_Natural.An = [];
          AgregarFilaAN();
        }

        const CharPercentage = (e,index) => {
          if(e != '')
          {
              if(!e.includes('%'))
            {
                objetoInfoAccionista_Natural.An[index].ParticipacionCapitalAN = objetoInfoAccionista_Natural.An[index].ParticipacionCapitalAN;
            }     
          }         
        }

        const validacionInfoAccionista_Natural = useVuelidate(reglasValidacion, objetoInfoAccionista_Natural, { $scope: props.scopeInfoAccionista_Natural })

        const tiposIdentificacion = [{
            value: "2",
            label: "CC",
          },
          {
            value: "3",
            label: "CE",
          },
          {
            value: "5",
            label: "PAS",
          },
          {
            value: "6",
            label: "PEP",
          }
        ]

        onUpdated(() => {
            window.scrollTo(0, 0);
        })

        onMounted(() => {
          let i;

        if(props.infoFormularioAccNatural != "No se encuentra diligenciamiento del formulario para el empleador consultado" && props.infoFormularioAccNatural.an != null)
        {      
          if((props.infoFormularioAccNatural.an.length > 0) && (Object.values(props.infoFormularioAccNatural.an[0]).some(x => x !== null && x !== '' && x !== undefined)))
          {
            objetoInfoAccionista_Natural.An.splice(0, 1);
          }

             for(i = 0; i < props.infoFormularioAccNatural.an.length; i++)
            {
                if(Object.values(props.infoFormularioAccNatural.an[i]).some(x => x !== null && x !== '' && x !== undefined))
                {
                    objetoInfoAccionista_Natural.An.push({
                        NombreAN: props.infoFormularioAccNatural.an[i].nombreAN,
                        TipoIdAN: props.infoFormularioAccNatural.an[i].tipoIdAN,
                        NumeroIdentificacionAN: props.infoFormularioAccNatural.an[i].numeroIdentificacionAN,
                        ParticipacionCapitalAN: props.infoFormularioAccNatural.an[i].participacionCapitalAN,
                        EsPEPAN: props.infoFormularioAccNatural.an[i].esPEPAN,
                        VinculosFamPEP_AN: props.infoFormularioAccNatural.an[i].vinculosFamPEP_AN
                    }); 
                }             
            }
        }
      })

        return { 
          AgregarFilaAN,
          EliminarFilaAN,
          validacionInfoAccionista_Natural, objetoInfoAccionista_Natural, reiniciarObjetoInfoAccionista_Natural,CharPercentage,func,
          tiposIdentificacion
        }
    },
    methods:{
        ValidaSiPEPAN()
        {
            let i;
            let Result = false;
            for(i = 0; i < this.objetoInfoAccionista_Natural.An.length; i++)
            {
                if(this.objetoInfoAccionista_Natural.An[i].EsPEPAN.includes("Si") || this.objetoInfoAccionista_Natural.An[i].VinculosFamPEP_AN.includes("Si"))
                {
                    Result = true;
                    break;
                }
                
            }
            this.$emit('MostrarSeccionPepAN',Result)
        },
        ValidarCamposFila(index)
        {
          let FilaLlena = !Object.values(this.objetoInfoAccionista_Natural.An[index]).some(x => x === null || x === '' || x === undefined)
          if(FilaLlena)
          {
            this.$emit('GenerarGuardadoParcial');
          }
        }
    }
})

</script>
