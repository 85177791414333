<template>
  <el-form label-position="top" label-width="auto" class="greenItem" >
    <el-row>
      <el-col style="color:#706262;">
        <p class="ParrafosTextoDebidaDiligencia">
          {{ textoDebidaDiligencia }}
        </p>
        <p style="text-align: center;">
          Si quieres conocer más acerca de este proceso haz <a
            @click="Download('conocemasdeladebidadiligencia.pdf')"
            class="documentoDDDownload">clic aquí</a>
        </p>
      </el-col>
    </el-row>
    <h2 class="titleDD">Datos de empresa</h2>
    <el-row :gutter="24" justify="space-evenly">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <el-form-item label="Nombre o Razón social*">
          <el-input v-model="objetoContacto.Data.Nombre" :disabled="true" />
        </el-form-item>
        <el-form-item v-for="error of validacionContacto.Nombre.$errors" :key="error.$uid"> 
          {{ error.$message }} 
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="Tipo de identificación*">
          <el-select v-model="objetoContacto.Data.TipoDocumento" placeholder="Seleccionar" :disabled="true">
            <el-option
              v-for="item in parametricas.tipoDeIdentificacion"
              :key="item.codigo"
              :label="item.valor"
              :value="item.valor"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-for="error of validacionContacto.TipoDocumento.$errors" :key="error.$uid"> 
          {{ error.$message }} 
        </el-form-item>
      </el-col> 

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="N° de Identificación*">
          <el-input v-model="objetoContacto.Data.No_Identificacion" :disabled="true"/>
        </el-form-item>
        <el-form-item v-for="error of validacionContacto.No_Identificacion.$errors" :key="error.$uid"> 
          {{ error.$message }} 
        </el-form-item>
      </el-col>    

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="Tipo de Persona*"> 
          <el-select v-model="objetoContacto.Data.TipoPersona" placeholder="Seleccionar" @change="ValidarCamposLlenos()">
            <el-option
              v-for="item in parametricas.tipoDePersona"
              :key="item.codigo"
              :label="item.valor"
              :value="item.valor"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-for="error of validacionContacto.TipoPersona.$errors" :key="error.$uid"> 
          {{ error.$message }} 
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item>
          <template #label>
            <span>Clase de sociedad* </span>
            <el-tooltip
              content="Para obtener esta información revisa el certificado de existencia y representación o el RUT de la sociedad. Ten en cuenta que una sociedad comercial se 
              constituye generalmente con escritura publica o documento privado, incluye varios socios o accionistas, aporta capital y tiene como objetivo generar utilidades, 
              formando una entidad jurídica propia. Si estos criterios no se cumplen, es posible que corresponda a una persona natural y puedes seleccionar entre empresario 
              individual o unipersonal."
              placement="top"
              open-delay="0"
              effect="customized"
            >
              <font-awesome-icon :icon="['fas', 'info-circle']" color="#00805d" style="font-size: 13px;" />
            </el-tooltip>
          </template>       
          <el-select v-model="objetoContacto.Data.ClaseSociedad" placeholder="Seleccionar" @change="ValidarCamposLlenos()">
            <el-option
              v-for="item in parametricas.claseDeSociedad"
              :key="item.codigo"
              :label="item.valor"
              :value="item.valor"
            />
          </el-select>         
        </el-form-item>
        <el-form-item v-for="error of validacionContacto.ClaseSociedad.$errors" :key="error.$uid"> 
          {{ error.$message }} 
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="Tipo de empresa*">
          <el-select v-model="objetoContacto.Data.TipoEmpresa" placeholder="Seleccionar" @change="ValidarCamposLlenos()">
            <el-option
              v-for="item in parametricas.tipoDeEmpresa"
              :key="item.codigo"
              :label="item.valor"
              :value="item.valor"
            />
          </el-select>  
        </el-form-item>
        <el-form-item v-for="error of validacionContacto.TipoEmpresa.$errors" :key="error.$uid"> 
          {{ error.$message }} 
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" v-if="objetoContacto.Data.TipoEmpresa != 'Otra'">
        
      </el-col>      

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" v-if="objetoContacto.Data.TipoEmpresa == 'Otra'">
        <el-form-item label="Si es otra ¿Cuál?">
          <el-input v-model="objetoContacto.Data.OtroTipoEmpresa" @change="ValidarCamposLlenos()"/>
        </el-form-item>
        <el-form-item v-for="error of validacionContacto.OtroTipoEmpresa.$errors" :key="error.$uid"> 
          {{ error.$message }} 
        </el-form-item>
      </el-col>    

      <divipola-dian
        ref="divipolaRef"
        :divipola="scopeDivipolas"
        :style="{ heigth: '20px' }"
        :footerPais="'&nbsp'"
        :requiredAsterisk="false"
        :divipolaModel="objetoDivipola"       
      ></divipola-dian>

      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <h2 class="titleDD" >Datos de contacto</h2>
      </el-col>

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="Nombre de Contacto*">
          <el-input v-model="objetoContacto.Data.NombreContactoCliente" @change="ValidarCamposLlenos()" />
        </el-form-item>
        <el-form-item v-if="validacionContacto.NombreContactoCliente.$errors.length > 0" :key="validacionContacto.NombreContactoCliente.$errors[0].$uid">
          {{ validacionContacto.NombreContactoCliente.$errors[0].$message }}
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="Cargo del Contacto*">
          <el-input v-model="objetoContacto.Data.CargoContactoCliente" @change="ValidarCamposLlenos()"/>
        </el-form-item>
        <el-form-item v-if="validacionContacto.CargoContactoCliente.$errors.length > 0" :key="validacionContacto.CargoContactoCliente.$errors[0].$uid">
          {{ validacionContacto.CargoContactoCliente.$errors[0].$message }}
        </el-form-item>
      </el-col>
      
      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="Correo Electrónico (e-mail)*">
          <el-input v-model="objetoContacto.Data.CorreoContactoCliente" @change="ValidarCamposLlenos()"/>
        </el-form-item>
        <el-form-item v-if="validacionContacto.CorreoContactoCliente.$errors.length > 0" :key="validacionContacto.CorreoContactoCliente.$errors[0].$uid">
          {{ validacionContacto.CorreoContactoCliente.$errors[0].$message }}
        </el-form-item>
      </el-col>
      
      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="Celular*">
          <el-input v-model="objetoContacto.Data.CelularContactoCliente" @keypress="func.OnlyNumbers" @change="ValidarCamposLlenos()"/>
        </el-form-item>

        <el-form-item v-if="validacionContacto.CelularContactoCliente.$errors.length > 0" :key="validacionContacto.CelularContactoCliente.$errors[0].$uid">
          {{ validacionContacto.CelularContactoCliente.$errors[0].$message }}
        </el-form-item>
      </el-col>   
    </el-row>

  </el-form>
</template> 
 
<script>
import {ref, defineComponent, onMounted, onUnmounted} from 'vue'
import DivipolaDian from "@/components/layout/DivipolaDian.vue";
import useVuelidate from '@vuelidate/core';
import ContactoValidacion from '@/validation/InfoContacto';
import func from '@/validation/GeneralRules';

export default defineComponent({
  components: {
    DivipolaDian
  },
  props: {
        scopeContacto: String,
        contactoRef: {},
        parametricas: {},
        infoFormularioContacto: {},
        textoDebidaDiligencia: String
    },
    setup(props){
      const divipolaRef = ref();
      const objetoDivipola = ref();
      const Download = (nombreArchivo) => {
        validarPaisGuardar();
        const url =
          process.env.VUE_APP_Gateway_API + "DebidaDiligencia/DescargarArchivoBlobStorage?nombre="+nombreArchivo;
        window.open(url, "_blank");
      };
      
        let interval;
        let [objetoContacto, reglasValidacion] = new ContactoValidacion().ObtenerReglasValidacion();      
        const validacionContacto = useVuelidate(reglasValidacion, objetoContacto.Data, { $scope: props.scopeContacto }) 
        objetoContacto.Data.TipoDocumento = localStorage.getItem('TipoIdenticacionDD');
        objetoContacto.Data.No_Identificacion = localStorage.getItem('NumeroIdentificacionDD');
        objetoContacto.Data.Nombre = localStorage.getItem('RazonSocialDD');
        objetoContacto.Data.TipoPersona = props.infoFormularioContacto.tipoPersona;
        objetoContacto.Data.ClaseSociedad = props.infoFormularioContacto.claseSociedad;
        objetoContacto.Data.TipoEmpresa = props.infoFormularioContacto.tipoEmpresa;
        objetoContacto.Data.OtroTipoEmpresa = props.infoFormularioContacto.otroTipoEmpresa;
        objetoContacto.Data.Pais = props.infoFormularioContacto.pais;
        objetoContacto.Data.Departamento = props.infoFormularioContacto.departamento;
        objetoContacto.Data.Ciudad = props.infoFormularioContacto.ciudad;
        objetoContacto.Data.NombreContactoCliente = props.infoFormularioContacto.nombreContactoCliente;
        objetoContacto.Data.CargoContactoCliente = props.infoFormularioContacto.cargoContactoCliente;
        objetoContacto.Data.CorreoContactoCliente = props.infoFormularioContacto.correoContactoCliente;
        objetoContacto.Data.TelefonoContactoCliente = props.infoFormularioContacto.telefonoContactoCliente;
        objetoContacto.Data.ExtensionContactoCliente = props.infoFormularioContacto.extensionContactoCliente;
        objetoContacto.Data.CelularContactoCliente = props.infoFormularioContacto.celularContactoCliente;     
      
        function validarPaisCargar(){        
         if(props.infoFormularioContacto.pais !== 'COLOMBIA')
        {
          objetoDivipola.value = {
                  Pais: props.infoFormularioContacto.pais,
                  Departamento: '',
                  Municipio: ''
          };          
        }
        else 
        {
          objetoDivipola.value = {
                  Pais: props.infoFormularioContacto.pais,
                  Departamento: props.infoFormularioContacto.departamento,
                  Municipio: props.infoFormularioContacto.ciudad
          }; 
        }
      }
      function validarPaisGuardar(){
        if (divipolaRef.value.divipolas.Pais === '')
        {
        objetoContacto.Data.Pais = '';    
        objetoContacto.Data.Departamento= '';
        objetoContacto.Data.Ciudad = '';
        }
        else if(divipolaRef.value.divipolas.Pais !== 'COLOMBIA')
        {
        objetoContacto.Data.Pais = divipolaRef.value.divipolas.Pais;    
        objetoContacto.Data.Departamento= '';
        objetoContacto.Data.Ciudad = '';  
        }
        else
        {
          objetoContacto.Data.Pais = divipolaRef.value.divipolas.Pais;
          objetoContacto.Data.Departamento= divipolaRef.value.divipolas.Departamento;
          objetoContacto.Data.Ciudad = divipolaRef.value.divipolas.Municipio;
        }         
        }

      const iniciarIntervalo = () => {
      interval = setInterval(() => {
        validarPaisGuardar()
      }, 1000);
    };
        onMounted(() => {
          validarPaisCargar();
          iniciarIntervalo();
        })
        
        onUnmounted(() => {
      clearInterval(interval);
    });
        return { 
          objetoContacto, validacionContacto, func, Download, divipolaRef, objetoDivipola, validarPaisCargar, validarPaisGuardar,iniciarIntervalo
        };
    },
    methods:{
      ValidarCamposLlenos()
      {
        var CantidadCamposLlenos = Object.values(this.objetoContacto.Data).filter(x => (x !== null) && (x !== "") && (x !== undefined)).length;
        if(CantidadCamposLlenos === 6 || CantidadCamposLlenos === 12 || CantidadCamposLlenos === 18)
        {
          this.$emit('GenerarGuardadoParcial');
        }
      }
    }  
})
</script>
