import { reactive } from 'vue'
import { required, helpers, minLength, maxLength, requiredIf } from '@vuelidate/validators'
import GeneralRules from './GeneralRules';

class InfoRepresentantePepValidacion{

     public ObtenerReglasValidacion() {

         const objetoInfoRepresentantePep = reactive({
             RLPep:[{
                NombreRLPEP: '',
                NombrePEP: '',
                TipoIdPEPRL: '',
                NumeroIdentificacionPEPRL: '',
                TipoVinculoPEPRL: '',
                TipoPEPRL: ''
             }],
         });

         const requiredMessage = 'Campo obligatorio';
         
         const patternRule = (pattern: RegExp) => ({
            $validator: (value: string) => pattern.test(value),
            trigger: ['blur', 'change'],
            });

        const validarNumeroPorTipoId = helpers.withMessage
            ('No se admiten espacios ni caracteres especiales', 
                (value: string, {TipoIdPEPRL}:{TipoIdPEPRL:string}) => {
                    if(!value || !TipoIdPEPRL) return false;
                        return GeneralRules.ValidateRegex(value,TipoIdPEPRL);      
            });
        
        const validarTamanoNumero = helpers.withMessage
            ('El rango de caracteres no es admitido', 
                (value: string, {TipoIdPEPRL}:{TipoIdPEPRL:string}) => {
                    if(!value || !TipoIdPEPRL) return false;
                        return GeneralRules.ValidateSize(value,TipoIdPEPRL);      
            });

        const reglaCaracteresEspeciales = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ]+( [a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$/;
         
         const reglasValidacion = {
            RLPep: {
                 $each: helpers.forEach({
                    NombreRLPEP: {
                         required: helpers.withMessage(requiredMessage, required),
                         minLength: helpers.withMessage('El rango de caracteres admitidos en este campo es de 2 a 100',minLength(2)),  
                         maxLength: helpers.withMessage('El rango de caracteres admitidos en este campo es de 2 a 100',maxLength(100)),         
                         patternRuleCarecteres: helpers.withMessage('No se admiten espacios al inicio o al final ni caracteres especiales', patternRule(reglaCaracteresEspeciales))
                     },
                     NombrePEP: {
                         required: helpers.withMessage(requiredMessage, required),
                         minLength: helpers.withMessage('El rango de caracteres admitidos en este campo es de 2 a 100',minLength(2)),  
                         maxLength: helpers.withMessage('El rango de caracteres admitidos en este campo es de 2 a 100',maxLength(100)),         
                         patternRuleCarecteres: helpers.withMessage('No se admiten espacios al inicio o al final ni caracteres especiales', patternRule(reglaCaracteresEspeciales))
                     },
                     TipoIdPEPRL: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     NumeroIdentificacionPEPRL: {
                        required: helpers.withMessage(requiredMessage, required),
                        validarNumeroPorTipoId,
                        validarTamanoNumero
                     },
                     TipoVinculoPEPRL: {
                         required: helpers.withMessage(requiredMessage, required),
                         maxLength: helpers.withMessage('El rango de caracteres en este campo es de 1 a 10',maxLength(10)),
                         patternRuleCarecteres: helpers.withMessage('No se admiten espacios ni caracteres especiales', patternRule(reglaCaracteresEspeciales))
                     },
                     TipoPEPRL: {
                        required: helpers.withMessage(requiredMessage, required),
                    }                  
                 })
             }     
         };

        return [objetoInfoRepresentantePep, reglasValidacion] as const;
    }
}

export default InfoRepresentantePepValidacion;